// Header.tsx
import React from "react";
import "../styles/Header.css";

const Header: React.FC = () => {
  return (
    <header className="hd02">
      <div className="test_title01">2024학년도 우주소녀능력시험 문제지</div>
      <div className="test_title02">우주소녀 영역</div>
    </header>
  );
};

export default Header;
