// Loading.tsx
import React from "react";

const Loading: React.FC = () => {
  return (
    <div className="loading-overlay">
      <p>채점 중...</p>
      <div className="ldbox">
        <img src="/assets/img/uj_01.png" alt="img" />
      </div>
    </div>
  );
};

export default Loading;
