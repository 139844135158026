// Answers.tsx
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/Answers.css";
import "../styles/Questions.css";
import "../styles/Questions02.css";

const Answers = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { score, name, answers, examNumber } = location.state || {};

  useEffect(() => {
    if (!name) {
      navigate("/");
    }
  }, [name, navigate]);

  const handleback = () => {
    navigate("/result", {
      state: { name, score, answers, examNumber },
    });
  };

  return (
    <main>
      <div className="ra_tt_box">
        <div className="rebtncontain">
          <button className="rebtn" onClick={handleback}>
            <img src="./assets/left.svg" className="leftsvg" alt="img" />
          </button>
        </div>
        <div className="an_text">
          <span>• 우주소녀 영역 •</span>
        </div>
      </div>

      <div className="coan_tt02">정　답</div>

      <table className="an_tb">
        <tr>
          <td className="td_1">1</td>
          <td>④</td>
          <td className="td_1">14</td>
          <td>⑤</td>
        </tr>

        <tr>
          <td className="td_1">2</td>
          <td>⑤</td>
          <td className="td_1">15</td>
          <td>③</td>
        </tr>

        <tr>
          <td className="td_1">3</td>
          <td>②</td>
          <td className="td_1">16</td>
          <td>⑤</td>
        </tr>

        <tr>
          <td className="td_1">4</td>
          <td>③</td>
          <td className="td_1">17</td>
          <td>①</td>
        </tr>

        <tr>
          <td className="td_1">5</td>
          <td>③</td>
          <td className="td_1">18</td>
          <td>④</td>
        </tr>

        <tr>
          <td className="td_1">6</td>
          <td>②</td>
          <td className="td_1">19</td>
          <td>③</td>
        </tr>

        <tr>
          <td className="td_1">7</td>
          <td>③</td>
          <td className="td_1">20</td>
          <td>②</td>
        </tr>

        <tr>
          <td className="td_1">8</td>
          <td>②</td>
          <td className="td_1">21</td>
          <td>③</td>
        </tr>

        <tr>
          <td className="td_1">9</td>
          <td>②</td>
          <td className="td_1">22</td>
          <td>④</td>
        </tr>

        <tr>
          <td className="td_1">10</td>
          <td>④</td>
          <td className="td_1">23</td>
          <td>②</td>
        </tr>

        <tr>
          <td className="td_1">11</td>
          <td>⑤</td>
          <td className="td_1">24</td>
          <td>③</td>
        </tr>

        <tr>
          <td className="td_1">12</td>
          <td>③</td>
          <td className="td_1">25</td>
          <td>②</td>
        </tr>

        <tr>
          <td className="td_1">13</td>
          <td>③</td>
          <td className="td_1">26</td>
          <td>④</td>
        </tr>
      </table>

      <div className="tbbox">
        <table className="an_tb02">
          <tr>
            <td className="td_2">27</td>
            <td className="td_3">십, 돌판, 예의, 응있지</td>
          </tr>
          <tr>
            <td className="td_2">28</td>
            <td className="td_3">유니버스, 허니제이, 걸스힙합</td>
          </tr>
          <tr>
            <td className="td_2">29</td>
            <td className="td_3">225 </td>
          </tr>
          <tr>
            <td className="td_2">30</td>
            <td className="td_3">
              오늘의, 플레이리스트, 전지적, 문, 헬스교실, 마음대로, 희희낙락,
              요리교실, 우쥬크박스, 우주뉴스
            </td>
          </tr>
        </table>
      </div>
    </main>
  );
};

export default Answers;
