// useAudioPlayers.ts
import { useEffect, useState } from "react";
import AudioPlayer from "./AudioPlayer";

export const useAudioPlayers = (audioFiles: string[]) => {
  const [players, setPlayers] = useState<AudioPlayer[]>([]);
  const [isPlaying, setIsPlaying] = useState<{ [key: number]: boolean }>({});
  const [isPlayed, setIsPlayed] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    // 오디오 플레이어 인스턴스 초기화
    if (players.length === 0) {
      // 오디오 플레이어가 아직 생성되지 않았다면
      const newPlayers = audioFiles.map((file, index) => {
        const player = new AudioPlayer(file);
        player.onEnded(() => {
          setIsPlayed((prev) => ({ ...prev, [index]: true }));
          setIsPlaying((prev) => ({ ...prev, [index]: false }));
        });
        return player;
      });
      setPlayers(newPlayers);
    }
  }, [audioFiles, players.length]); // 종속성 배열에 audioFiles와 players.length를 포함

  const playPause = (index: number) => {
    if (isPlayed[index]) return;

    if (isPlaying[index]) {
      players[index].pause();
      setIsPlaying((prev) => ({ ...prev, [index]: false }));
    } else {
      players[index].playOnce();
      setIsPlaying((prev) => ({ ...prev, [index]: true }));
    }
  };

  const resetPlayer = (index: number) => {
    if (isPlaying[index] && !isPlayed[index]) {
      players[index].pause();
      players[index].reset();
      setIsPlaying((prev) => ({ ...prev, [index]: false }));
    }
  };

  return { players, isPlaying, isPlayed, playPause, resetPlayer };
};
