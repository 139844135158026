export const getGrade = (score: any): string => {
  if (score === 100) return "★1등급★";
  if (score >= 90) return "1등급";
  if (score >= 80) return "2등급";
  if (score >= 70) return "3등급";
  if (score >= 60) return "4등급";
  if (score >= 50) return "5등급";
  if (score >= 40) return "6등급";
  if (score >= 30) return "7등급";
  if (score >= 20) return "8등급";
  if (score >= 1) return "9등급";
  if (score === 0) return "측정 불가";

  return "?등급";
};

export const getPosition = (score: any): string => {
  if (score === 100) return "★웆능 전국 수석 만점자 우정★";
  if (score >= 90) return "걸어다니는 우소 나무위키 우정";
  if (score >= 80) return "자나깨나 우소 생각하는 우정";
  if (score >= 70) return "우소와 사랑에 빠진 우정";
  if (score >= 60) return "우소를 더 사랑하게 될 우정";
  if (score >= 50) return "우소에게 관심 많은 우정";
  if (score >= 40) return "앞으로 더 알아가면 되는 우정";
  if (score >= 30) return "이제 우소 덕질 시작한 우정";
  if (score >= 20) return "응애 나 아기 우정";
  if (score >= 1) return "어쨌든 아무튼 그래도 우정";
  if (score === 0) return "우주소녀 검색부터 해야 할 우정";
  return "우정";
};

export const getGradeDetails = (grade: any) => {
  switch (grade) {
    case "★1등급★":
      return {
        image: "/assets/img/ujpt_00.png",
        style: {},
      };
    case "1등급":
      return {
        image: "/assets/img/ujpt_01.png",
        style: {},
      };
    case "2등급":
      return {
        image: "/assets/img/ujpt_02.png",
        style: {},
      };
    case "3등급":
      return {
        image: "/assets/img/ujpt_03.png",
        style: {},
      };
    case "4등급":
      return {
        image: "/assets/img/ujpt_04.png",
        style: {},
      };
    case "5등급":
      return {
        image: "/assets/img/ujpt_05.png",
        style: {},
      };
    case "6등급":
      return {
        image: "/assets/img/ujpt_06.png",
        style: {},
      };
    case "7등급":
      return {
        image: "/assets/img/ujpt_07.png",
        style: {},
      };
    case "8등급":
      return {
        image: "/assets/img/ujpt_08.png",
        style: {},
      };
    case "9등급":
      return {
        image: "/assets/img/ujpt_09.png",
        style: {},
      };
    case "측정 불가":
      return {
        image: "/assets/img/ujpt_10.png",
        style: {},
      };
    default:
      return {
        image: "/assets/img/ujpt_dt.png",
        style: {},
      };
  }
};
