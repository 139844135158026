// ReviewAnswers.tsx
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import questionsData from "../utils/questionsData";
import { applyNewTabLinks } from "../utils/Link";
import DOMPurify from "dompurify";
import { useAudioPlayers } from "../utils/useAudioPlayers";
import "../styles/Questions.css";
import "../styles/Questions02.css";
import "../styles/ReviewAnswers.css";

const ReviewAnswers: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { score, name, answers, examNumber } = location.state || {};
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const currentQuestionNumber = currentQuestionIndex + 1; // 현재 문항 번호 (0부터 시작하므로 1을 더함)

  const currentQuestion = questionsData[currentQuestionIndex];
  const userAnswer = answers ? answers[String(currentQuestion.id)] : undefined;
  let isCorrect = false;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      applyNewTabLinks(containerRef.current);
    }
  }, [currentQuestion]);

  // 듣기 평가
  // 각 오디오 파일에 대한 재생 상태
  const audioFiles = [
    "./assets/sound/01번.mp3",
    "./assets/sound/02번.mp3",
    "./assets/sound/03번.mp3",
    "./assets/sound/04번.mp3",
    "./assets/sound/05번.mp3",
  ];
  const { isPlaying, isPlayed, playPause, resetPlayer } =
    useAudioPlayers(audioFiles);

  useEffect(() => {
    // answers 값이 없거나 특정 질문에 대한 사용자의 답변이 없는 경우 메인 페이지로 리다이렉트
    if (!answers || typeof userAnswer === "undefined") {
      navigate("/");
    }
  }, [answers, userAnswer, navigate]);

  const createMarkup = (html: any) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const handleback = () => {
    navigate("/result", {
      state: { name, score, answers, examNumber },
    });
  };

  // 다음 문제 및 이전 문제 버튼
  const goToNextQuestion = () => {
    if (isPlaying[currentQuestionIndex] && !isPlayed[currentQuestionIndex]) {
      resetPlayer(currentQuestionIndex);
    }
    if (currentQuestionIndex < questionsData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const goToPreviousQuestion = () => {
    if (isPlaying[currentQuestionIndex] && !isPlayed[currentQuestionIndex]) {
      resetPlayer(currentQuestionIndex);
    }
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const getOptionClasses = (option: string, index: number) => {
    let optionNumberClass = "option-number";
    let opTextClass = "op_text";
    let isCorrectOption = false;

    if (currentQuestion.type === "multiple-choice") {
      // 객관식 문제일 경우, correctAnswer는 숫자 타입의 인덱스입니다.
      isCorrectOption = index === currentQuestion.correctAnswer;

      // 사용자가 선택한 답변이 현재 옵션의 인덱스와 일치하는지 확인
      const isSelected = index === Number(userAnswer);

      if (isCorrectOption) {
        optionNumberClass += " correct-option-number";
        opTextClass += " correct-op-text";
      } else if (isSelected) {
        optionNumberClass += " user-selected-option-number";
        opTextClass += " user-selected-op-text";
      }
    } else if (currentQuestion.type === "short-answer") {
      // 단답형 문제일 경우
      isCorrectOption = currentQuestion.correctAnswer.includes(option);

      if (isCorrectOption) {
        optionNumberClass += " correct-option-number";
        opTextClass += " correct-op-text";
      } else if (option === userAnswer) {
        optionNumberClass += " user-selected-option-number";
        opTextClass += " user-selected-op-text";
      }
    }

    return { optionNumberClass, opTextClass };
  };

  const isAnswerCorrect = (
    type: string,
    correctAnswer: number | string | string[],
    userAnswer: number | string | string[]
  ) => {
    // console.log("Type:", type);
    // console.log("Correct Answer:", correctAnswer);
    // console.log("User Answer:", userAnswer);
    if (type === "multiple-choice") {
      // 객관식의 경우, 숫자로 변환하여 비교
      return Number(correctAnswer) === Number(userAnswer);
    } else if (type === "short-answer") {
      if (Array.isArray(correctAnswer)) {
        // 단답형이 배열인 경우
        return (
          Array.isArray(userAnswer) &&
          correctAnswer.length === userAnswer.length &&
          correctAnswer.every((answer, index) => answer === userAnswer[index])
        );
      } else {
        // 단답형이 배열이 아닌 경우
        return String(correctAnswer) === String(userAnswer);
      }
    }
    return false;
  };

  const findCorrectAnswerIndex = () => {
    if (currentQuestion.type === "multiple-choice") {
      // 객관식 문제일 경우, correctAnswer는 숫자로, 옵션 배열의 인덱스입니다.
      return (currentQuestion.correctAnswer as number) + 1;
    }
    return null; // 'short-answer' 유형의 경우 null 반환
  };

  // 문항 렌더링
  const renderAnswer = () => {
    if (currentQuestion.type === "multiple-choice") {
      return (
        <ul className="q_opt">
          {currentQuestion.options.map((option, index) => {
            const isSelected = index === Number(userAnswer);
            const isCorrectOption = index === currentQuestion.correctAnswer;
            const { optionNumberClass, opTextClass } = getOptionClasses(
              option,
              index
            );

            // 여기에서 option과 userAnswer를 비교합니다.
            return (
              <li key={index}>
                <div className="seleclist">
                  <div className={optionNumberClass}>
                    <div className="ckbox02">
                      {isSelected && (
                        <img
                          src="./assets/check.svg"
                          className="anchecksvg01"
                          alt="img"
                        />
                      )}
                      {isCorrectOption && (
                        <img
                          src="./assets/red-check.svg"
                          className="anchecksvg02"
                          alt="img"
                        />
                      )}
                    </div>
                    {index + 1}
                  </div>
                  <span className={opTextClass}>{option}</span>
                </div>
              </li>
            );
          })}
        </ul>
      );
    } else if (currentQuestion.type === "short-answer") {
      // 사용자가 입력한 답변이 배열일 때, 각 답변을 쉼표로 구분하여 표시합니다.
      if (Array.isArray(userAnswer)) {
        return (
          <div className="user_as">
            나의 답 :
            {userAnswer.map((answer, index) => (
              <span key={index}>
                {answer}
                {index < userAnswer.length - 1 && ", "}
              </span>
            ))}
          </div>
        );
      } else {
        return <div className="user_as">나의 답 : {userAnswer}</div>;
      }
    }
  };

  isCorrect = isAnswerCorrect(
    currentQuestion.type,
    currentQuestion.correctAnswer,
    userAnswer
  );

  // 로그를 통해 답변 데이터 확인
  // console.log("User answers:", answers);
  // console.log(
  //   "Current question correct answer:",
  //   currentQuestion.correctAnswer
  // );
  // console.log("User answer for current question:", userAnswer);
  // console.log("Is answer correct?", isCorrect);

  return (
    <main>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20,100,0,-25"
      />

      <div className="ra_tt_box">
        <div className="rebtncontain">
          <button className="rebtn" onClick={handleback}>
            <img src="./assets/left.svg" className="leftsvg" alt="img" />
          </button>
        </div>
        <div className="coan_tt">정답 및 해설</div>
      </div>

      {/* O, X  */}
      <div>
        {isCorrect ? (
          <div className="correct-answer">
            <img src="./assets/O.svg" className="O_svg" alt="img" />
          </div>
        ) : (
          <div className="incorrect-answer">
            <img src="./assets/X.svg" className="X_svg" alt="img" />
          </div>
        )}
      </div>

      {/* 문제 표시 */}
      <div className="qn_contain">
        <div className="q_num">{currentQuestionNumber}.</div>
        <div dangerouslySetInnerHTML={createMarkup(currentQuestion.question)} />

        {currentQuestionNumber === 1 && (
          <div
            className="q_sound"
            onClick={() => playPause(0)} // 첫 번째 오디오에 대해 playPause 함수 호출
            style={{ backgroundColor: isPlayed[0] ? "grey" : "white" }}
          >
            <img
              src={
                isPlaying[0] ? "./assets/pause.svg" : "./assets/play_arrow.svg"
              }
              className="playicon"
              alt="img"
            />
            <div>1번 듣기 평가 문제</div>
          </div>
        )}

        {currentQuestionNumber === 2 && (
          <div
            className="q_sound"
            onClick={() => playPause(1)}
            style={{ backgroundColor: isPlayed[1] ? "grey" : "white" }}
          >
            <img
              src={
                isPlaying[1] ? "./assets/pause.svg" : "./assets/play_arrow.svg"
              }
              className="playicon"
              alt="img"
            />
            <div>2번 듣기 평가 문제</div>
          </div>
        )}

        {currentQuestionNumber === 3 && (
          <div
            className="q_sound"
            onClick={() => playPause(2)}
            style={{ backgroundColor: isPlayed[2] ? "grey" : "white" }}
          >
            <img
              src={
                isPlaying[2] ? "./assets/pause.svg" : "./assets/play_arrow.svg"
              }
              className="playicon"
              alt="img"
            />
            <div>3번 듣기 평가 문제</div>
          </div>
        )}

        {currentQuestionNumber === 4 && (
          <div
            className="q_sound"
            onClick={() => playPause(3)}
            style={{ backgroundColor: isPlayed[3] ? "grey" : "white" }}
          >
            <img
              src={
                isPlaying[3] ? "./assets/pause.svg" : "./assets/play_arrow.svg"
              }
              className="playicon"
              alt="img"
            />
            <div>4번 듣기 평가 문제</div>
          </div>
        )}

        {currentQuestionNumber === 5 && (
          <div
            className="q_sound"
            onClick={() => playPause(4)}
            style={{ backgroundColor: isPlayed[4] ? "grey" : "white" }}
          >
            <img
              src={
                isPlaying[4] ? "./assets/pause.svg" : "./assets/play_arrow.svg"
              }
              className="playicon"
              alt="img"
            />
            <div>5번 듣기 평가 문제</div>
          </div>
        )}

        {renderAnswer()}

        <div className="ancontain">
          <span className="cr_tt">정답</span>
          {currentQuestion.type === "multiple-choice" && (
            <div className="multi_num">{findCorrectAnswerIndex()}</div>
          )}
          {currentQuestion.type === "short-answer" && (
            <div
              dangerouslySetInnerHTML={createMarkup(
                currentQuestion.correctAnswer
              )}
            />
          )}
        </div>

        <div className="explaincontain">
          <p className="exp_tt">해설</p>

          <div
            className="expbox"
            ref={containerRef}
            dangerouslySetInnerHTML={createMarkup(currentQuestion.explanation)}
          />
        </div>
      </div>
      {/* 이전/다음 버튼 */}
      <div className="prvnextbtnbox">
        {currentQuestionIndex > 0 && (
          <button className="prvbtn" onClick={goToPreviousQuestion}>
            이전
          </button>
        )}

        {currentQuestionIndex < questionsData.length - 1 && (
          <button className="nextbtn" onClick={goToNextQuestion}>
            다음
          </button>
        )}
      </div>
    </main>
  );
};

export default ReviewAnswers;
