import React, { createContext, useContext, useState } from "react";

const ResultImageContext = createContext();

export const useResultImage = () => useContext(ResultImageContext);

export const ResultImageProvider = ({ children }) => {
  const [resultImage, setResultImage] = useState(null);

  return (
    <ResultImageContext.Provider value={{ resultImage, setResultImage }}>
      {children}
    </ResultImageContext.Provider>
  );
};
