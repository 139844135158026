// questionsData.ts

const questionsData = [
  //  듣기 문제 1~5번
  {
    id: 1,
    type: "multiple-choice" as "multiple-choice",
    question: `
  <div class="q_box">
  다음 반주를 듣고 해당 부분의 가사로 옳은 것을 고르시오. [3점]
  </div>
      `,
    options: [
      "찬란하게 빛나지 않아도 모든 걸 비춰주지 않아도 외로운 방 안에 온기가 되어 줘요 그렇게 있어줘요",
      "찬란하게 빛나지 않아도 모든 걸 비춰주지 않아도 외로운 방 안에 온기가 되어 줘요 그렇게 있어줘요",
      "눈부시게 빛나지 않아도 모든 걸 비춰주지 않아도 외로운 내 품에 온기가 되어 줘요 그렇게 안아줘요",
      "눈부시게 빛나는 곳에선 깊은 잠에 빠질 수 없어요 포근한 그 등빛 아래서 잠이 들래요 그렇게 있어줘요",
      "눈부시게 빛나는 곳에선 깊은 잠에 빠질 수 없어요 포근한 그 별빛 아래서 잠이 들래요 그렇게 있어줘요",
    ],
    correctAnswer: 3,
    explanation: `
    해당 반주는 '야광별'의 2절 부분이다. 
    따라서 '야광별' 2절에 해당하는 가사로 옳은 것은 ④번 '눈부시게 빛나는 곳에선 깊은 잠에 빠질 수 없어요 포근한 그 등빛 아래서 잠이 들래요'가 된다.
    
    <div class="exp_tt02"> 
    참고사항
    </div>
    <p>'야광별' 2분 11초 ~ 2분 32초</p>
    <a class="link01" href="https://youtu.be/D6SLIu0r-Bk?si=0PNM7NwMnnyPjim_&t=131" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>야광별</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/D6SLIu0r-Bk</span> 
    </div>
    </a>
    

    `,
    score: 3,
  },

  {
    id: 2,
    type: "multiple-choice" as "multiple-choice",
    question: `
  <div class="q_box">
  다음을 듣고, 멤버들이 나눈 대화의 주제에 대한 설명으로 적절한 것을 고르시오. [3점]
  </div>
      `,
    options: [
      "이 무대에서 리믹스 버전의 곡으로 무대를 선보였다.",
      "‘더쇼’에서 보여줄 ‘이루리’ 1위 공약 무대로 마법학교 코스프레에 대한 의견을 나누고 있다.",
      "우정들에게 보여줄 ‘우쭈테잎 스페셜 영상’으로 마법학교 코스프레에 대한 의견을 나누고 있다.",
      "이 무대에 대해 은서가 의견을 제시한 것 중, 바닥에 연기 효과가 반영되었다.",
      "이 무대에 대해 다영이 의견을 제시한 것 중, 무대의 컨페티로 ‘검정 깃털’이 반영되었다.",
    ],
    correctAnswer: 4,
    explanation: `
    
    듣기 속 대화는 ‘더쇼’ ‘부탁해 1위 공약 + 막방이라’의 
    무대를 위해 멤버들이 마법 학교 코스프레에 대한 의견을 나누는 상황이다. 
    정답은 ⑤번 “이 무대에 대해 다영이 의견을 제시한 것 중, 무대의 컨페티로 
    ‘검정 깃털’이 반영되었다.”가 된다. 
    
    <div class="exp_tt02"> 
    오답 해설
    </div>
    ④ 은서가 아닌 여름이 의견을 제시하였다.

    <div class="exp_tt02"> 
    참고사항
    </div>
    <a class="link01" href="https://youtu.be/dqC2_B_rDLo" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>우주소녀, 막방이라 WJSN, THE SHOW SPECIAL GOOD BYE STAGE</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/dqC2_B_rDLo</span> 
    </div>
    </a>
    <a class="link01" href="https://youtu.be/cFBkY_jpq48" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>THE SHOW 181016 우주소녀 부탁해</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/cFBkY_jpq48</span> 
    </div>
    </a>

    `,
    score: 3,
  },

  {
    id: 3,
    type: "multiple-choice" as "multiple-choice",
    question: `
  <div class="q_box">
  다음 멤버의 곡 설명을 듣고, 해당 곡에 대한 설명으로 옳은 것을 고르시오. [2점]
  </div>
      `,
    options: [
      "이 곡의 무대 소품으로 ‘우산’을 이용한 안무가 있다.",
      "이 곡의 무대 소품은 우주소녀 매니저들이 직접 만들었다.",
      "우주소녀 콘서트 ‘WONDERLAND’에서 이 곡의 무대를 한 적이 있다.",
      "멤버들은 흰 장갑을 낀 채로 안무한다.",
      "앨범 'WJ PLEASE?'의 수록곡이다.",
    ],
    correctAnswer: 1,
    explanation: `
    듣기 속 음성은 2020년 우주소녀 온라인 콘서트 ‘AS WE WISH’ VCR 중 한 부분이다. 
    루다가 곡 ‘You Got’을 소개하는 부분이다. 
    ‘You Got’의 가사인 ‘그만하자’ 라는 부분과 
    ‘멋진 소품이 등장하니까요’라는 부분을 듣고 
    ‘You Got’임을 유추할 수 있다. 
    ‘You Got’에선 지팡이 소품을 활용한 안무가 있다. 
    지팡이는 우주소녀 매니저들이 직접 만들었다고 
    ‘히든트랙 - 우주소녀 편’에서 말한 적이 있다. 
    따라서 답은 ②번 "이 곡의 무대 소품은 우주소녀 매니저들이 직접 만들었다."가 된다.
   
    <div class="exp_tt02"> 
    참고사항
    </div>
     <a class="link01" href="https://youtu.be/EiY6EB-iPiA?si=JnX2eFg1GdcIrlNJ&t=4311" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>히든트랙 - 우주소녀 편</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/EiY6EB-iPiA</span> 
    </div>
    </a>

    <a class="link01" href="https://youtu.be/WHZb_plLGoU" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>우주소녀 - You Got</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/WHZb_plLGoU</span> 
    </div>
    </a>
    `,
    score: 2,
  },

  {
    id: 4,
    type: "multiple-choice" as "multiple-choice",
    question: `
  <div class="q_box">
  다음을 듣고, 상황에 대한 설명으로 옳은 것을 고르시오. [2점]</div>
      `,
    options: [
      "다영은 숙소에서 V LIVE ‘다영이의 요리교실’ 도중 엑시에게 전화를 걸었다.",
      "이어서 엑시는 임창정의 ‘소주 한 잔’을 부르며 다영의 전화를 받았다.",
      "이어서 엑시는 뉴이스트의 ‘여보세요’를 부르며 다영의 전화를 받았다.",
      "다영은 엑시와 전화 통화 후, 이어서 여름에게 전화를 걸었다.",
      "다영은 엑시와 전화 통화 후, 이어서 스타쉽 부사장에게 전화를 걸었다.",
    ],
    correctAnswer: 2,
    explanation: `듣기 속 음성은 2022년 5월 14일 다영의 생일 V LIVE의 한 부분이다. 
    듣기의 마지막 부분을 이어서 엑시는 뉴이스트의 ‘여보세요’를 부르며 다영의 전화를 받았다. 
    따라서 답은 ③번이 된다.
    
    <div class="exp_tt02"> 
    참고사항
    </div>
   
    <a class="link01" href="https://www.youtube.com/shorts/Zh3LeFz2Mi8" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>이거 브이앱이야!!!</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://www.youtube.com/shorts/Zh3LeFz2Mi8</span> 
    </div>
    </a>

    <a class="link01" href="https://youtu.be/R6MYKKd5Rgc" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>쥬쥬의 프린세스 다이어리 놀이🎂🎀</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/R6MYKKd5Rgc</span> 
    </div>
    </a>
    `,
    score: 2,
  },

  {
    id: 5,
    type: "multiple-choice" as "multiple-choice",
    question: `
  <div class="q_box">
  다음을 듣고, 이어질 말로 옳은 것을 고르시오. [2점]</div>
      `,
    options: [
      "탄단지다.",
      "영양제다.",
      "쌀밥이다.",
      "강화섬 쌀밥이다.",
      "임금님표 이천 쌀밥이다.",
    ],
    correctAnswer: 2,
    explanation: `
    듣기 속 음성은 2022년 우주소녀 콘서트 ‘WONDERLAND’ VCR 중 한 부분이다. 
    ‘나에게 우정이란?’ 이라는 질문에 수빈은 ‘쌀밥이다.’ 라고 답했다. 
    따라서 답은 ③번이 된다.

    <div class="exp_tt02"> 
    참고사항
    </div>
   
    <p>2:28 ~ 2:38</p>
    <a class="link01" href="https://youtu.be/56PSMETalwo?si=veOP1BX02aOBXhLg&t=148" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>우주소녀 콘서트 WONDERLAND - 엔딩 VCR</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/56PSMETalwo</span> 
    </div>
    </a>

    `,
    score: 2,
  },

  // 5지선다형 6~
  {
    id: 6,
    type: "multiple-choice" as "multiple-choice",
    question: `
    <div class="q_box">
    다음 자료는 우주소녀가 게릴라 공연을 했던 지역을 지도에 표시한 것이다.
    답글 ㉠~㉣ 중에서 옳은 내용만을 있는 대로 고른 것은? [4점]
    </div>
    <div class="q_picbox" style="border: 0px solid;">
    <img src="/assets/q_img/qs_01.png" />
    </div>
        `,
    options: ["ㄱ, ㄴ", "ㄱ, ㄹ", "ㄱ, ㄴ, ㄷ", "ㄱ, ㄷ, ㄹ", "ㄴ, ㄷ, ㄹ"],
    correctAnswer: 1,
    explanation: `
    자료 속 지도는 서울이며 A는 서대문구, B는 강남구, C는 송파구다.<br/> 
해당 지역에서 우주소녀가 게릴라 공연을 했던 것을 정리해보면 아래와 같다.<br/> <br/> 
2016.09.10 A 서울 서대문구 신촌 유플렉스 앞 광장<br/> 
- ‘MoMoMo’, ‘비밀이야’, ‘BeBe’<br/> <br/> 
2017.07.01 A 서울 서대문구 신촌 명물거리 <br/> 
- ‘HAPPY’, ‘주세요’, ‘이층침대’, ‘비밀이야’ <br/> <br/> 
2017.06.18 B 서울 강남구 코엑스 라이브 플라자<br/> 
- ‘HAPPY’, ‘비밀이야’, ‘너에게 닿기를’<br/> <br/> 
2017.07.16 B 서울 강남구 수서역 SRT<br/> 
- ‘HAPPY’, ‘KISS ME’, ‘너에게 닿기를’, '비밀이야’<br/> <br/> 
2017.06.13 C 서울 송파구 서울종합운동장 잠실야구장<br/> 
- ‘HAPPY’, '너에게 닿기를’<br/> <br/> 
A에서 2016년 9월 10일 'MoMoMo' 공연을 선보였으므로 ㉠은 옳은 선지이다. 
㉡의 B 중 2017년 6월 18일 코엑스 라이브 플라자에서 실내 공연을 하였다. 
하지만 이 날을 제외한 모든 게릴라 공연은 실외에서 이루어졌다. 
따라서 ㉡ 선지는 틀린 선지이다. ㉢의 A 중 2017년 7월 1일 'HAPPY' 무대 의상을 입었으나,
 C에선 우주소녀가 송파 경찰청 홍보대사로 경찰 제복을 입고 공연을 선보였다. 
 따라서 ㉢은 틀린 선지이다. ㉣은 B, C 모두 '너에게 닿기를' 
 공연을 선보였으므로 옳은 선지가 된다. 답은 
②번 ㄱ, ㄹ이 된다.
 

<div class="exp_tt02"> 
참고사항
</div>

<a class="link01" href="https://youtu.be/4hlyW341PUE" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>160910 우주소녀 (WJSN,Cosmic Girls) - MoMoMo [전체] 직캠 Fancam (신촌게릴라공연) by Mera
</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/4hlyW341PUE</span> 
</div>
</a>

<a class="link01" href="https://youtu.be/tBJD0bPlqX8" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>170701 우주소녀 게릴라 공연 신촌 명물거리
</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/tBJD0bPlqX8</span> 
</div>
</a>

<a class="link01" href="https://youtu.be/Vx6EiWymdTo" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>[4K] 170618 우주소녀 WJSN Cosmic Girls 宇宙少女 Full Cam @ 코엑스 by Sleeppage
</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/Vx6EiWymdTo</span> 
</div>
</a>

<a class="link01" href="https://youtu.be/0-fRqCDE48Y" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>170716 우주소녀(WJSN) Full ver. [수서역 게릴라] (Happy & 너에게 닿기를 & Kiss Me & 비밀이야) 4K 직캠 by 비몽
</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/0-fRqCDE48Y</span> 
</div>
</a>

<a class="link01" href="https://youtu.be/0CiiHCKLMok" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>WJSN (Cosmic Girls)(우주소녀) _ HAPPY(해피) 경찰 제복 @170613 잠실 게릴라 [4k Fancam/직캠
</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/0CiiHCKLMok</span> 
</div>
</a>


`,
    score: 4,
  },

  {
    id: 7,
    type: "multiple-choice" as "multiple-choice",
    question: `
    <div class="q_box">
    그림은 우주소녀 멤버 5명을 주어진 기준에 따라 분류한 것이다.
    <div class="q_picbox" style="border: 0px solid;margin-top: 28px;margin-bottom: 28px;">
    <img src="/assets/q_img/qt_01.png" />
    </div>
    이에 대한 설명으로 옳은 것만을 <보기>에서 있는 대로 고른 것은? [4점]
    </div>
    <div class="pvbox_02">
              <div class="q_pvtt_02">&lt;보 기&gt;</div>
              <div class="q_pvtt_03">
          <p class="indent_text">ㄱ. 곡 ‘UNNATURAL’에서 ㉡과 ㉢의 페어 안무가 있다.</p>
          <p class="indent_text">ㄴ. ㉠과 ㉣의 출생지는 경상도다.</p>
          <p class="indent_text">ㄷ. ㉣과 ㉤의 이름은 순우리말 이름이다.</p>
               </div>
        `,
    options: ["ㄴ", "ㄱ, ㄴ", "ㄱ, ㄷ", "ㄴ, ㄷ", "ㄱ, ㄴ, ㄷ"],
    correctAnswer: 2,
    explanation: `
    서바이벌 프로그램에 개인으로 출연한 멤버는 엑시(언프리티 랩스타 2, 두 번째 세계) , 
    루다(소녀 리버스), 여름(퀸덤 퍼즐), 연정(프로듀스 101, V-1, 로또싱어) 이다. 
    이 중 2회 이상 출연한 멤버는 엑시와 연정이다. <br/><br/>
    서바이벌 프로그램에 개인으로 2회 이상 출연한 적이 있으며, 직접 작사, 작곡한 곡이 있는 멤버는 ㉠ 엑시다. <br/>
    서바이벌 프로그램에 개인으로 2회 이상 출연한 적이 있으며, 직접 작사, 작곡을 한 적이 없는 멤버는 ㉡ 연정이다.<br/>
    서바이벌 프로그램에 개인으로 2회 이상 출연한 적이 없으며, 서울공연예술고등학교를 
    졸업하고 현재 반려동물을 키우고 있는 멤버는 ㉢ 여름이다.<br/>
    서바이벌 프로그램에 개인으로 2회 이상 출연한 적이 없으며, 서울공연예술고등학교를 
    졸업하고 현재 반려동물을 키우고 있지 않은 멤버는 ㉣ 은서다.<br/>
    서바이벌 프로그램에 개인으로 2회 이상 출연한 적이 없으며, 서울공연예술고등학교를 졸업하지 않은 멤버는 ㉤ 루다이다. 
    <br/><br/>
보기의 ㄱ 'UNNATURAL'에서 ㉡ 연정, ㉢ 여름 '마음에 안 들어' 부분에 페어 안무가 있으므로 옳은 설명이다. ㄴ은 
㉠ 엑시는 출생지로 부산으로 경상도가 맞지만 ㉣ 은서는 인천이므로 틀린 설명이다. 
ㄷ의 ㉢ 여름, ㉤ 루다 여름과 루다의 이름은 순우리말 이름이 맞으므로 옳은 설명이다. 따라서 답은 ③번 ㄱ, ㄷ 이 된다.
    `,
    score: 4,
  },

  {
    id: 8,
    type: "multiple-choice" as "multiple-choice",
    question: `
            <div class="q_box">
                그림은 우주소녀 멤버들의 형제자매 관계를 표현한 것이다. A~C에 해당하는
                설명으로 옳은 것만을 <보기>에서 있는 대로 고른 것은? [3점]
            </div>

            <div class="q_pic_01">
                <img src="/assets/q_img/q_01.png" />
                <div class="pvbox">
                    <div class="q_pvtt">&lt;범 례&gt;</div>
                    <div>A : 언니가 있는 멤버<br/>
                    B : 언니, 남동생이 있는 멤버<br/>
                     C : 남동생이 있는 멤버<br/>
                     </div>
                </div>
            </div>

            <div class="pvbox_02">
                <div class="q_pvtt_02">&lt;보 기&gt;</div>
                <div class="q_pvtt_03">
                <p class="indent_text"> ㄱ. A에 생일이 동일한 멤버가 존재한다.</p>
                <p class="indent_text">ㄴ. B에 동일한 뮤지컬을 함께 한 멤버가 존재한다.</p>
                <p class="indent_text">ㄷ. B에 동갑인 멤버가 존재한다.</p>
                <p class="indent_text">ㄹ. C에 우주소녀에서 동갑인 멤버가 존재한다.</p>
                </div>
            </div>
            `,
    options: ["ㄱ, ㄴ", "ㄱ, ㄹ", "ㄷ, ㄹ", "ㄱ, ㄴ, ㄷ", "ㄴ, ㄷ, ㄹ"],
    correctAnswer: 1,
    explanation: `
    A 언니가 있는 멤버는 엑시, 설아, 루다, 다원, 은서이고 C 남동생이 있는 멤버는 엑시, 수빈, 여름, 연정이다. B 언니와 남동생이 있는 멤버는 엑시가 된다.
    보기 ㄱ의 A 생일이 동일한 멤버는 다원과 은서가 있으므로 옳은 설명이다. 
    보기 ㄴ, ㄷ 중 B는 엑시 한 명이기 때문에 둘 다 틀린 설명이다. 
    ㄹ은 1999년생인 여름, 연정이 있으므로 옳은 설명이다. 따라서 답은 ② ㄱ, ㄹ이다.

    <div class="exp_tt02"> 
    참고사항
    </div>
   
  <p>보나는 오빠 한 명이 있고, 다영은 외동딸이다.</p>
`,
    score: 3,
  },

  {
    id: 9,
    type: "multiple-choice" as "multiple-choice",
    question: `
        <div class="q_box">
        다음 자료는 우주소녀 멤버들이 말실수 한 순간이다.
        (가)~(라)에 대한 설명으로 옳은 것은? [3점]
        </div>

        <div class="q_picbox">
            <p>(가)</p>
            <img src="/assets/q_img/qa_01.jpg" />
        </div>

        <div class="q_picbox">
            <p>(나)</p>
            <img src="/assets/q_img/qa_02.jpg" />
        </div>

        <div class="q_picbox">
            <p>(다)</p>
            <img src="/assets/q_img/qa_03.jpg" />
        </div>
        <div class="q_picbox">
            <p>(라)</p>
            <img src="/assets/q_img/qa_04.jpg" />
        </div>
          `,
    options: [
      "(가)에서 은서가 미션젠가를 러브젠가라고 말실수를 했다.",
      "(가)에서 엑시가 미션젠가를 러브젠가라고 말실수를 했다.",
      "(나)에서 다영이 브라질너트를 브라질리언이라고 말실수를 했다.",
      "(다)에서 루다가 팬츠사장을 팬티사장이라고 말실수를 했다.",
      "(라)에서 엑시가 사랑의 폭풍을 생리의 폭풍이라고 말실수를 했다.",
    ],
    correctAnswer: 1,
    explanation: `
    답 ②번 2018년 11월 1일 엑시, 수빈, 은서의 V LIVE '아귀르떼스의 패션체인지💜'에서 한 말실수다. 
   
    <div class="exp_tt02"> 
    참고사항
    </div>
   
     <a class="link01" href="https://youtu.be/-2e2_PxHQ64?si=yqJSHHIhjMSY6ras&t=1559" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>181101 아귀르떼스의 패션체인지💜</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/-2e2_PxHQ64</span> 
    </div>
    </a>

    <a class="link01" href="https://youtu.be/7JPGuwnp_4o" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[우주소녀]브라질(리언) 너트
    </p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/7JPGuwnp_4o</span> 
    </div>
    </a>

     <a class="link01" href="https://youtu.be/zSExO8kAp84" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[우주소녀] 팬티사장 이루다
    </p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/zSExO8kAp84</span> 
    </div>
    </a>

    <a class="link01" href="https://www.youtube.com/shorts/tLwWNlr1wrQ?feature=share" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[우주소녀 설아]전설의 생리의 폭풍 래핑
    </p>
   <span><img src="./assets/player.svg" className="playersvg" />https://www.youtube.com/shorts/tLwWNlr1wrQ?feature=share</span> 
    </div>
    </a>
    `,

    score: 3,
  },

  {
    id: 10,
    type: "multiple-choice" as "multiple-choice",
    question: `
        <div class="q_box">
        다음 사진에 대한 설명으로 옳지 <u>않은</u> 것은? [3점]
        </div>
          <div class="q_picbox">
               <img src="/assets/q_img/qb_01.jpg" />
          </div>

            `,
    options: [
      "이 날 엑시는 앵콜 무대 때 우느라 본인 파트를 부르지 못했다.",
      "이 날 루다는 음악 방송의 스페셜 MC였다.",
      "우주소녀가 데뷔 이후 음악 방송에서 첫 1위를 한 날이다.",
      "2018년 10월 2일 〈쇼챔피언〉에서 '부탁해'라는 곡으로 1위를 하였다.",
      "음원과 음반 점수를 합한 총 점수는 5000점이었다.",
    ],
    correctAnswer: 3,
    explanation: `④번 <쇼챔피언>이 아닌 <더쇼>이다.
    <div class="exp_tt02"> 
    참고사항
    </div>
   
     <a class="link01" href="https://youtu.be/AfkY7BTJHhw" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>THE SHOW 181002 우주소녀 부탁해 1위</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/AfkY7BTJHhw</span> 
    </div>
    </a>
    <a class="link01" href="https://youtu.be/8QLnoyc6ZGI" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>THE SHOW 181002 우주소녀 부탁해 1위 앵콜 무대</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/8QLnoyc6ZGI</span> 
    </div>
    </a>
    `,
    score: 3,
  },

  {
    id: 11,
    type: "multiple-choice" as "multiple-choice",
    question: `
        <div class="q_box">
        2023년 ‘코드네임:우정(CODENAME:UJUNG)’ 팬 콘서트에서 쪼꼬미와 더 블랙 멤버들이 체인지 무대를 선보였다.
        이 날 멤버들이 서로 바꾼 역할로 옳은 것은? [3점]
        </div>
          `,
    options: [
      "쪼꼬미의 수빈은 더 블랙의 엑시 파트를 맡았다.",
      "쪼꼬미의 루다는 더 블랙의 설아 파트를 맡았다.",
      "쪼꼬미의 다영은 더 블랙의 보나 파트를 맡았다.",
      "더 블랙의 은서는 쪼꼬미의 다영 파트를 맡았다.",
      "더 블랙의 보나는 쪼꼬미의 여름 파트를 맡았다.",
    ],
    correctAnswer: 4,
    explanation: `
    더 블랙과 쪼꼬미 멤버들은 
    엑시 ↔ 다영, 설아 ↔ 수빈, 보나 ↔ 여름, 은서 ↔ 루다 이렇게
    서로 역할을 바꿔서 무대했다. 
    따라서 답은 ⑤번 "더 블랙의 보나는 쪼꼬미의 여름 파트를 맡았다.”가 된다.

    <div class="exp_tt02"> 
    참고사항
    </div>
   
     <a class="link01" href="https://youtu.be/1mXo4b_iLQo" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>EASY - 쪼꼬미 ver.</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/1mXo4b_iLQo</span> 
    </div>
    </a>
    <a class="link01" href="https://youtu.be/0HAOU_g1uh4" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>흥칫뿡 - 더 블랙 ver.</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/0HAOU_g1uh4</span> 
    </div>
    </a>
    `,
    score: 3,
  },

  {
    id: 12,
    type: "multiple-choice" as "multiple-choice",
    question: `
          <div class="q_box">
              다음은 우주소녀의 노래 가사다. 가사를 보고
              곡이 발매 된 순서로 옳은 것은? [3점]
          </div>

          <div class="q_pvtt_04">
              <div class="q_pvtt_05">
                  <p>(가)</p> <br />
                  잊을 건 잊어 내일은 그냥 내일<br />
                  좋은 꿈만 꾸길 바래 눈처럼 맑게<br />
                  아무 걱정하지 말고 잘자<br />
              </div>
              <div class="q_pvtt_05">
                  <p>(나)</p> <br />
                  마치 오늘의 주인공인 것처럼 <br />
                  세상에 내가 제일 예쁜 것처럼<br />
                  눈부시게 빛나는<br />
                  우리만의 Night out<br />
              </div>
              <div class="q_pvtt_05">
                  <p>(다)</p> <br />
                  새로운 미랠 향해<br />
                  잔잔한 결을 흩트려 놔<br />
                  I wanna ruin it now<br />
                  기다려지지 그다음 그다음 그다음<br />
              </div>
              <div class="q_pvtt_05">
                  <p>(라)</p> <br />
                  너에게 줄게 12시가 될 때<br />
                  한 줄기 빛으로 꿈을 남길게<br />
                  날 믿어줄래 놓치지 않을래<br />
              </div>
          </div>
          `,
    options: [
      "(가)-(다)-(나)-(라)",
      "(가)-(나)-(라)-(다)",
      "(가)-(라)-(나)-(다)",
      "(나)-(라)-(다)-(가)",
      "(나)-(가)-(라)-(다)",
    ],
    correctAnswer: 2,
    explanation: `
    (가)~(라)를 순서대로 나타내보면 아래와 같다.<br/><br/>
<p class="indent_text">(가) 2016.08.17 ‘THE SECRET’ - 이층침대 (Good Night)</p> 
<p class="indent_text">(나) 2019.06.04 ‘SPECIAL ALBUM [For the Summer]’ - 우리끼리</p> 
<p class="indent_text">(다) 2021.03.31 ‘UNNATURAL’ - New Me</p> 
<p class="indent_text">(라) 2019.01.08 ‘WJ STAY?’ - 12 O'clock</p> 
<br/>
따라서 답은 ③ (가) - (라) - (나) - (다)
    `,
    score: 3,
  },

  {
    id: 13,
    type: "multiple-choice" as "multiple-choice",
    question: `
<div class="q_box">
밑줄 친 ‘최종 우승’을 한 시기를 연표에서 옳게 고른 것은? [3점]
</div>
<div class="pvbox02">
<p style="margin: 0;">
우주소녀는 결국 ‘AURA’ 무대로 <u>최종 우승</u>을 차지하며 이들의 진가를 입증해냈다. 
그동안 ‘퀸덤2’ 경연에서 2위를 차지하며 차근차근 실력을 인정받아왔던 우주소녀. 
파이널 무대는 우주소녀의 확장된 스펙트럼과 한층 더 탄탄해진 퍼포먼스 
서사를 입증하는 무대가 됐다. 
우주소녀는 대체불가의 무대를 완성하며 팬들에게 최종 선택을 받았다.
</p>
</div>
  <div class="q_picbox" style="border:0px;margin-top: 19px;max-width: 600px;">
      <img src="/assets/q_img/qn_01.png" />
      </div>
    `,
    options: ["(가)", "(나)", "(다)", "(라)", "(마)"],
    correctAnswer: 2,
    explanation: `
    '퀸덤2' 최종 우승한 날짜는 2022년 6월 2일이다.<br/><br/>
2022년 2월 12일<br/> 드라마 '스물다섯 스물하나' 드라마 첫 방영<br/>
2022년 3월 24일<br/> 뮤지컬 '리지' 첫 공연 <br/>
2022년 4월 19일<br/> '예뻤어' 설아 커버 영상 업로드<br/>
2022년 6월 2일<br/> 퀸덤2 파이널 생방송 - 최종 우승<br/>
2022년 6월 4일<br/> 놀라운 토요일 도레미 마켓 엑시 보나 출연분 방영<br/>
2022년 6월 11일<br/> 원더랜드 'WONDERLAND' 콘서트<br/>
2022년 7월 5일<br/> 우주소녀 컴백쇼 시퀀스<br/>
<br/>
따라서 정답은 ③ (다)
    `,
    score: 3,
  },

  {
    id: 14,
    type: "multiple-choice" as "multiple-choice",
    question: `
      <div class="q_box">
      (가)~(라)는 우주소녀 멤버들의 안무 연습 사진이다. 
      이에 대한 설명으로 옳은 것만을 <보기>에서 있는 대로 고른 것은? [4점]
      </div>

      <div class="q_picbox">
      <p>(가)</p>
      <img src="/assets/q_img/qv_01.jpg" />
      </div>

      <div class="q_picbox">
      <p>(나)</p>
      <img src="/assets/q_img/qv_02.jpg" />
      </div>
      <div class="q_picbox">
      <p>(다)</p>
      <img src="/assets/q_img/qv_03.jpg" />
      </div>
      <div class="q_picbox">
      <p>(라)</p>
      <img src="/assets/q_img/qv_04.jpg" />
      </div>

      <div class="pvbox_03">
                  <div class="q_pvtt_02">&lt;보 기&gt;</div>
                  <div class="q_pvtt_03">
         <p class="indent_text">ㄱ. (가)는 곡 ‘Last Sequence’의 안무이다.</p>
         <p class="indent_text">ㄴ. (나)의 안무는 연습 하고 있는 곡의 가장 첫 부분에 해당한다.</p>
         <p class="indent_text">ㄷ. (다)의 무대 중 엑시, 설아, 보나, 루다는 공중곡예를 한다.</p>
         <p class="indent_text">ㄹ. (가)와 (라)에서 안무 연습하는 곡은 해당 곡의 앨범 수록곡이다.</p>
                   </div>
              </div>
        `,
    options: ["ㄱ", "ㄱ, ㄴ", "ㄱ, ㄴ, ㄹ", "ㄱ, ㄷ, ㄹ", "ㄴ, ㄷ, ㄹ"],
    correctAnswer: 4,
    explanation: `
    문제 속 (가)~(라)의 안무 연습 곡은 아래와 같다.<br/><br/>
(가) 'Done'<br/>
(나) 'Hurry Up'<br/>
(다) 'Pantomime'<br/>
(라) '바램'<br/>
<br/>
<보기>의 ㄱ은 ‘Last Sequence’가 아닌 'Done'이어야하므로 틀린 선지이다. 
ㄴ은 'Hurry Up'의 시작 부분의 안무이므로 옳은 선지이다. 
ㄷ은 '퀸덤2' 'Pantomime' 무대에서 공중곡예를 하므로 옳은 선지이다. 
ㄹ은 (라)는 'Neverland' 앨범의 수록곡 '바램'이고, (가)는 'Sequence'의 수록곡 'Done'이므로 옳은 선지이다. 
따라서 답은 ⑤ ㄴ, ㄷ, ㄹ 이 된다.
  
<div class="exp_tt02"> 
참고사항
</div>

 <a class="link01" href="https://youtu.be/TWtTYDSqiE8?si=trpT5VhYKghleky3&t=748" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>[UZZU TAPE] EP.184 'Sequence' 앨범 준비 비하인드 02화</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/TWtTYDSqiE8</span> 
</div>
</a>

<a class="link01" href="https://youtu.be/eT57n4LoGmg" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>[Special Clip] 우주소녀(WJSN) - Hurry Up Fixed Cam Ver.
</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/eT57n4LoGmg</span> 
</div>
</a>

<a class="link01" href="https://youtu.be/HClwiAVV68k" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>[우주소녀] 잠깐 스쳐지나간 연습장면 (Pantomime)
</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/HClwiAVV68k</span> 
</div>
</a>

<a class="link01" href="https://youtu.be/ECNuATslibU?si=Oto1bzu14u4nSTOI&t=1269" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[UZZU TAPE] EP.100 Neverland 앨범 준비 비하인드!</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/ECNuATslibU</span> 
    </div>
    </a>
`,
    score: 4,
  },

  {
    id: 15,
    type: "multiple-choice" as "multiple-choice",
    question: `
        <div class="q_box">
            다음 퍼즐 속 세로 낱말 (A)에 대한 설명으로 옳은 것은? [4점]
        </div>

        <div class="pzbox">
            <div class="q_picbox">
                <img src="/assets/q_img/qw_01.jpg" />
            </div>

            <div class="pztt">
                <div style="
              margin-bottom: 17px;margin-top: 17px;
            ">
                    [가로 열쇠] <br />
                    <p>(A): 연습생 시절 멤버들끼리 가위바위보 해서 지는 사람은
                        카페에 가서 "이 사이즈"로 달라며 커피를 주문했다고 한다.</p>
                    <p> (B): 우주소녀 멤버 중 한 명이 직접 작사, 작곡에 참여한 노래이며 앨범 'Neverland'의 수록곡이다.
                    트로피컬 하우스 리듬에 청량한 플럭 소스, 감성적인 복스(vox) 리드가 매력적인 일렉트로니카 장르의 곡이다.</p>
                    <p> (C): 우주소녀 멤버가 이 예능에 출연하여 학창 시절 하굣길에 자신을 뒤쫓아온 남자가 있었다며 자신이 겪은 현실 괴담을 풀었다.</p>
                </div>
                <div>
                    [세로 열쇠]<br />
                    (A): …
                </div>
            </div>
        </div>
        `,
    options: [
      "우정들을 위한 팬송이다.",
      "엑시와 다원이 이 곡의 작사, 작곡에 참여했다.",
      "팬미팅에서 이 곡의 무대를 한 적이 있다.",
      "우주소녀 멤버가 키우는 반려동물이다.",
      "우주소녀 멤버 중 한 명은 이 방송에 출연했다.",
    ],
    correctAnswer: 2,
    explanation: `
    가로 열쇠 (A)는 엑시가 V LIVE에서 말한 연습생 시절 일화이다. 
    이때 내기에서 진 설아가 카페에 들어가 “카페라떼 아리아나 그란데 사이즈로 주세요.”라며 주문을 했다고 한다. 가로 (A)는 ‘아리아나 그란데’이다.
가로 (B)에 해당하는 곡은 엑시가 직접 작사, 작곡에 참여한 ‘불꽃놀이’다. 가로 (B)는 ‘불꽃놀이’다.
가로 (C)는 2021년 6월 3일 방송된 ‘심야괴담회’에 보나가 출연하여 밝힌 일화이다. (C)는 ‘심야괴담회’이다.
따라서 세로 (A)는 ‘아이야’가 된다. 우정 2기 팬미팅 때 ‘아이야’ 무대를 선보인 적이 있으므로, 우주소녀의 곡 ‘아이야’에 대한 설명으로 옳은 선지로는 ③번 "팬미팅에서 이 곡의 무대를 한 적이 있다.”가 된다.

<div class="exp_tt02"> 
참고사항
</div>

<p>아리아나 그란데 2:44 ~ 3:23</p>
    <a class="link01" href="https://youtu.be/jHp7MPFT_OI?si=Cp6im2bmvaivYGyp&t=164" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[우주소녀] 연습생 썰 2탄</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/jHp7MPFT_OI</span> 
    </div>
    </a>

    <a class="link01" href="https://youtu.be/Sr5R-gpziB4" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[심야괴담회] 보나 절대 지켜..😡 중학생 보나를 쫓아온 수상한 남자?! 긴급신고는 112👮🚨, MBC 210603 방송
    </p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/Sr5R-gpziB4</span> 
    </div>
    </a>

    <a class="link01" href="https://youtu.be/rnTUsJXs7nA" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>우정 2기 팬미팅 - 아이야
    </p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/rnTUsJXs7nA</span> 
    </div>
    </a>
`,
    score: 4,
  },

  {
    id: 16,
    type: "multiple-choice" as "multiple-choice",
    question: `
      <div class="q_box">
      그림은 우주소녀의 곡 ⓐ의 안무 대형을 위에서 본 시점이다.
      (가)는 시작 대형이고, (나)는 엔딩 대형이다.
      이 곡으로 활동 하던 시기의 특징으로 옳은 것은? [4점]
      </div>

      <div class="q_picbox">
      <p>(가)</p>
      <img src="/assets/q_img/qe_01.png" />
      </div>

      <div class="q_picbox">
      <p>(나)</p>
      <img src="/assets/q_img/qe_02.png" />
      </div>
        `,
    options: [
      "F는 머리를 분홍색으로 염색했다.",
      "곡 ⓐ에 영광의 얼굴들이 작곡으로 참여하였다.",
      "9개월 만에 곡 ⓐ로 컴백했다.",
      "A는 곡 ⓐ에서 고음 파트를 맡았다.",
      "곡 ⓐ로 '엠카운트다운'에서 1위를 했다.",
    ],
    correctAnswer: 4,
    explanation: `
    <p>ⓐ의 곡은 'BUTTERFLY'이다. (가), (나)의 대형은 참고사항에 있는 사진과 같다.
    문제 속 A~J의 멤버는 A 연정, B 루다, C 다영, D 은서, E 다원, 
    F 보나, G 설아, H 여름, I 수빈, J 엑시다.
    2020년 6월 18일 우주소녀가 'BUTTERFLY'로 엠카운트다운에서 1위를 했다. 
    따라서 정답은 ⑤번 '곡 ⓐ로 '엠카운트다운'에서 1위를 했다.'가 된다. </p>
   
    <div class="exp_tt02"> 
    오답 해설
    </div>
<p class="indent_text">① F는 보나로 틀린 선지이다. 분홍색으로 염색한 멤버는 설아와 다영이다.</p>
<p class="indent_text">② 별들의 전쟁이 작사, 작곡에 참여하였고 엑시가 작사에 참여했다.</p>
<p class="indent_text">③ 2019년 11월 'As You Wish' 이후 2020년 6월 9일에 'Neverland' 앨범을 발매했으므로 9개월이 아닌, 약 7개월 만이다.</p>
<p class="indent_text">④ A는 연정이다. 'BUTTERFLY'에서 고음 파트는 I인 수빈이 맡았다.</p>
<div class="exp_tt02"> 
    참고사항
    </div>
    <div class="q_picbox">
      <p>(가)</p>
      <img src="/assets/a_img/a_q01.jpg" />
      </div>

      <div class="q_picbox">
      <p>(나)</p>
      <img src="/assets/a_img/a_q02.jpg" />
      </div>

      <a class="link01" href="https://youtu.be/yBn8AcKXmAU" target="_blank" rel="noopener noreferrer">
      <div class="linkbox">
      <p>[MPD직캠] 우주소녀 1위 앵콜 직캠 4K 'BUTTERFLY' (WJSN FanCam No.1 Encore) | @MCOUNTDOWN_2020.6.18

      </p>
     <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/yBn8AcKXmAU</span> 
      </div>
      </a>

    `,
    score: 4,
  },

  {
    id: 17,
    type: "multiple-choice" as "multiple-choice",
    question: `
      <div class="q_box">
      엑시, 설아, 수빈, 다영이 다니고 있는 헤어, 메이크업샵 이름으로 옳은 것은? 
      (단, 시점은 2023년 12월 기준으로 한다.) [3점]
      </div>
          `,
    options: ["팀바이블룸", "위위아뜰리에", "프리빗", "제니하우스", "빗앤붓"],
    correctAnswer: 0,
    explanation: `
<p>
2023년 12월 기준으로 엑시, 설아, 수빈, 다영은 
'팀바이블룸'에서 헤어, 메이크업을 받았다.
</p>

    <div class="q_picbox">
    <img src="/assets/a_img/a_w01.jpg" />
    </div>

    <div class="exp_tt02"> 
    참고사항
    </div>

    <a class="link01" href="https://www.instagram.com/p/C0gFp0zr50J/?utm_source=ig_web_copy_link" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>팀바이블룸</p>
   <span><img src="./assets/instagram.svg"/ class="snsicon">https://www.instagram.com/p/C0gFp0zr50J/?utm_source=ig_web_copy_link</span> 
    </div>
    </a>
    `,
    score: 3,
  },

  {
    id: 18,
    type: "multiple-choice" as "multiple-choice",
    question: `
        <div class="q_box">
        우주소녀 앨범 타이틀 곡으로 최다 참여한 작곡가의 곡을 <보기>에 있는 대로 고른 것은? 
        (단, 타이틀 곡은 앨범 발매 후 공식적인 음악 방송 활동을 했던 곡을 기준으로 한다.) [4점]
        </div>
        <div class="pvbox_03">
              <div class="q_pvtt_02">&lt;보 기&gt;</div>
              <div class="q_pvtt_03">
              ㄱ. 우와<br/>
              ㄴ. 너, 너, 너<br/>
              ㄷ. 기적 같은 아이 (Miracle) <br/>
              ㄹ. My Type<br/>
              ㅁ. 행운을 빌어<br/>
              ㅂ. Last Dance <br/>
              ㅅ. 르네상스 <br/>
              ㅇ. HOLA <br/>
               </div>
          </div>
        `,
    options: [
      "ㄱ, ㄴ, ㄹ, ㅅ",
      "ㄱ, ㄷ, ㅁ, ㅇ",
      "ㄴ, ㄷ, ㄹ, ㅅ",
      "ㄴ, ㄹ, ㅁ, ㅂ",
      "ㄴ, ㄷ, ㄹ, ㅁ, ㅇ",
    ],
    correctAnswer: 3,
    explanation: `
    <p> 
우주소녀 타이틀 곡 작곡가는 참고사항과 같다. <br/>
영광의 얼굴들 (Full8loom)이 총 4회로 타이틀 곡에 최다 참여하였다. <보기>에서 영광의 얼굴들이 작곡에 참여한 곡은
ㄴ. '너, 너, 너', ㄹ. 'My Type', ㅁ. '행운을 빌어', ㅂ. 'Last Dance'이다. 따라서 답은 ④ ㄴ, ㄹ, ㅁ, ㅂ
<br/><br/>
정답을 제외한 나머지 <보기>에 있는 곡의 작곡가는 아래와 같다.<br/><br/>
ㄱ. 우와 - 정호현 (e.one), 최현준 (V.O.S)<br/>
ㄷ. 기적 같은 아이 (Miracle) - e.one<br/>
ㅅ. 르네상스 - 정호현 (e.one), 최현준 (V.O.S) <br/>
ㅇ. HOLA - KZ, Nthonius, 비오 (B.O.)<br/>
<div class="exp_tt02"> 
    참고사항
    </div>
🎵 MoMoMo (모모모)<br/>
김도훈 (RBW), 서용배 (TENTEN)<br/>
<br/>
🎵 비밀이야 (Secret)<br/>
e.one <br/>
<br/>
🎵 너에게 닿기를 (I Wish)<br/>
영광의 얼굴들 (Full8loom), 양갱, 진리 (Full8loom)<br/>
<br/>
🎵 HAPPY<br/>
블랙아이드 필승, 전군<br/>
<br/>
🎵 꿈꾸는 마음으로<br/>
영광의 얼굴들 (Full8loom), 진리 (Full8loom), Jake K (ARTiffect)<br/>
<br/>
🎵 부탁해<br/>
영광의 얼굴들 (Full8loom), 진리 (Full8loom), Jake K (ARTiffect)<br/>
<br/>
🎵 La La Love<br/>
영광의 얼굴들 (Full8loom), 진리 (Full8loom), Jake K (ARTiffect)<br/>
<br/>
🎵 Boogie Up<br/>Wonderkid, 신쿵<br/>
<br/>
🎵 이루리<br/>KZ, Nthonius, 비오 (B.O.)<br/>
<br/>
🎵 BUTTERFLY<br/>
별들의 전쟁 (GALACTIKA *), 아테나 (GALACTIKA *), 우빈 (GALACTIKA *)<br/>
<br/>
🎵 UNNATURAL<br/>케빈오빠, 송희진, Chris Wahle<br/>
<br/>
🎵 Last Sequence<br/>
Ludwig Lindell, Ronnie Icon, Paulina Cerrilla
</p> 
    



    `,
    score: 4,
  },

  {
    id: 19,
    type: "multiple-choice" as "multiple-choice",
    question: `
      <div class="q_box">
      표에 있는 우주소녀 곡의 안무가로 옳지 <u>않은</u> 것을 있는 대로 고른 것은? [4점]
      </div>

      <div class="table">
        <div class="row-hd" style="justify-content: center;font-weight: bold;">
          <div class="cell_num">번호</div>
          <div class="cell_date">년도</div>
          <div class="cell">곡 제목</div>
          <div class="cell-last">안무가</div>
        </div>
        <div class="row">
          <div class="cell_num">ⓐ</div>
          <div class="cell_date">2018</div>
          <div class="cell">꿈꾸는 마음으로</div>
          <div class="cell-last">최영준</div>
        </div>
        <div class="row">
          <div class="cell_num">ⓑ</div>
          <div class="cell_date">2018</div>
          <div class="cell">설레는밤</div>
          <div class="cell-last">최영준</div>
        </div>
        <div class="row">
          <div class="cell_num">ⓒ</div>
          <div class="cell_date">2018</div>
          <div class="cell">부탁해</div>
          <div class="cell-last">최영준</div>
        </div>
        <div class="row">
          <div class="cell_num">ⓓ</div>
          <div class="cell_date">2018</div>
          <div class="cell">너, 너, 너</div>
          <div class="cell-last">윤미연</div>
        </div>
        <div class="row">
          <div class="cell_num">ⓔ</div>
          <div class="cell_date">2019</div>
          <div class="cell">La La Love</div>
          <div class="cell-last">최영준</div>
        </div>
        <div class="row">
          <div class="cell_num">ⓕ</div>
          <div class="cell_date">2019</div>
          <div class="cell">이루리</div>
          <div class="cell-last">윤미연</div>
        </div>
        <div class="row">
        <div class="cell_num">ⓖ</div>
        <div class="cell_date">2020</div>
        <div class="cell">BUTTERFLY</div>
        <div class="cell-last">최영준</div>
        </div>
        <div class="row">
        <div class="cell_num">ⓗ</div>
        <div class="cell_date">2020</div>
        <div class="cell">바램</div>
        <div class="cell-last">채다솜</div>
      </div>
      <div class="row-last">
      <div class="cell_num">ⓘ</div>
      <div class="cell_date">2021</div>
      <div class="cell">Last Dance</div>
      <div class="cell-last">윤미연</div>
      </div>

      </div>
          `,
    options: [
      "ⓒ, ⓔ, ⓕ",
      "ⓒ, ⓕ, ⓗ, ⓘ",
      "ⓓ, ⓔ, ⓕ, ⓗ",
      "ⓓ, ⓔ, ⓗ, ⓘ",
      "ⓔ, ⓕ, ⓖ, ⓗ, ⓙ",
    ],
    correctAnswer: 2,
    explanation: `
    ⓓ '너, 너, 너', ⓔ 'La La Love', ⓕ '이루리'의 안무가는 채다솜(프리마인드)이다. 
    ⓗ '바램'의 안무가는 채다솜이 아닌 최영준이다. 따라서 답은 ③ ⓓ, ⓔ, ⓕ, ⓗ
  
    <div class="exp_tt02"> 
    참고사항
    </div>
    <a class="link01" href="https://youtu.be/dpZAm3tr9xU" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[안무가 시점 Ep.4] 우주소녀 (WJSN) - BUTTERFLY</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/dpZAm3tr9xU</span> 
    </div>
    </a>

     <a class="link01" href="https://youtu.be/-WtmzIdqPFg" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>우주소녀 - 바램 안무 시안 (Original Choreography Full Ver.)    </p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/-WtmzIdqPFg</span> 
    </div>
    </a>

    <a class="link01" href="https://youtu.be/xij-Td0Vyk8" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>우주소녀 '꿈꾸는 마음으로' 최영준 리허설 대타 Full ver.</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/xij-Td0Vyk8</span> 
    </div>
    </a>
    <a class="link01" href="https://youtu.be/QpIiKUP9LJw" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>우주소녀 - Last Dance 안무시안 (미연쌤 인스타 스토리)</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/QpIiKUP9LJw</span> 
    </div>
    </a>
     <a class="link01" href="https://youtu.be/W7LEAkYKr8U" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[FreeMind] 우주소녀 - 너, 너, 너 (안무시안)</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/W7LEAkYKr8U</span> 
    </div>
    </a>
    <a class="link01" href="https://youtu.be/Tw6al16Sz08" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[FreeMind] 우주소녀 - La La Love (안무시안)</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/Tw6al16Sz08</span> 
    </div>
    </a>
    <a class="link01" href="https://youtu.be/WB_Q5aAI_mQ" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[FreeMind] 우주소녀 - 이루리 (안무시안)</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/WB_Q5aAI_mQ</span> 
    </div>
    </a>
  
    `,
    score: 4,
  },

  {
    id: 20,
    type: "multiple-choice" as "multiple-choice",
    question: `
        <div class="q_box">
        (가), (나)는 우주소녀의 앨범 소개다.
        (가), (나)가 작성된 사이의 시기에 있었던 일로 옳은 것만을
        <보기>에서 있는 대로 고른 것은? [3점]
        </div>

        <div class="pvbox02">
        <p style="
        margin-bottom: 6px;
        ">
        <b>(가)</b>
        <div style="
        margin-bottom: 30px;
        ">
        소녀들이 가장 행복한 순간은 언제일까. 사랑을 막 시작하는 그 찰나의 순간 아닐까.
        세상의 모든 사물이 예뻐 보이고, 가만히 있어도 입가에 웃음이 번지고 콧노래가 절로 나오는
        그 순수하고도 맑은 시간들. 처음 느낀 감정에 벅차다가도 더 예뻐지고 싶은 마음에 괜히 거울을
        한 번 더 쳐다보는 모습들은 학창시절 누구나 한 번씩 겪었을 법한 예쁜 이야기다.
        </div>
        </p>

        <p style="
        margin-bottom: 6px;
        ">
        <b>(나)</b>
        <div>
        우주소녀가 순수하고 신비해서 더 매력적인 판타지를 선사한다.
        사랑에 빠진 순간, 간절해서 더 설레고 알 수 없는 감정이라 더욱 궁금한 새 음악이다.
        순수함과 신비로움에 대한 사랑, 묘한 상상 그 이상에 대한 이미지를 음악과 퍼포먼스,
        영상으로 구현해 호기심을 자극하기에 충분하다. 원한대로 이뤄지는 소녀들의 로맨스 판타지다. </p>
        </div>
        </div>

        <div class="pvbox_03">
        <div class="q_pvtt_02">&lt;보 기&gt;</div>
        <div class="q_pvtt_03">
        <p class="indent_text">ㄱ. 여름 스페셜 앨범을 발매하였다.</p> 
        <p class="indent_text">ㄴ. 우주소녀의 리얼리티 프로그램 '우주 LIKE 소녀'가 방영 되었다.</p> 
        <p class="indent_text">ㄷ. 데뷔 후 첫 일본 투어 콘서트를 개최하였다.</p> 
        <p class="indent_text">ㄹ. 우주소녀 첫 단독 콘서트 'WOULD YOU LIKE♥ - HAPPY MOMENT'를 개최했다.</p> 
        </div>
        </div>
        `,
    options: ["ㄱ, ㄴ", "ㄱ, ㄷ", "ㄴ, ㄹ", "ㄱ, ㄴ, ㄷ", "ㄱ, ㄷ, ㄹ"],
    correctAnswer: 1,
    explanation: `
    (가)는 2017년 6월 7일에 발매된 'HAPPY MOMENT', (나)는 2019년 11월 19일에 발매 된  'As You Wish'이다.<br/>
2017년 6월 7일 ~ 2019년 11월 19일 사이에 있었던 일로 옳은 것을 골라야한다. <br/>
<br/>
<p class="indent_text">ㄱ. 2019년 6월 4일 스페셜 앨범 'For the Summer'가 발매되었으므로 옳은 보기다.</p>
<p class="indent_text">ㄴ. '우주 LIKE 소녀'는 2016년 6월 9일 ~ 2016년 7월 28일까지 방영되었으므로 틀린 보기다.</p>
<p class="indent_text">ㄷ. 우주소녀는 데뷔 후 첫 일본 투어 2019년 8월 17일 도쿄 제프 다이버시티(Zepp DiverCity)를 
시작으로 24일 오사카 제프 오사카 베이사이드(Zepp Osaka Bayside), 25일 나고야 제프 나고야(Zepp Nagoya)에서 
총 3개 도시에서 공연을 개최했었으므로 옳은 보기다.</p>
<p class="indent_text">ㄹ. 'WOULD YOU LIKE♥ - HAPPY MOMENT' 콘서트는 2017년 5월 19일~20일 개최되었으므로 틀린 보기다.</p><br/>
따라서 답은 ② ㄱ, ㄷ이 된다.
    `,
    score: 3,
  },

  {
    id: 21,
    type: "multiple-choice" as "multiple-choice",
    question: `
      <div class="q_box">
      그림은 2023년 팬콘 'CODENAME : UJUNG'에서 다원, 연정이 무대한
      '우주소녀 스페셜 메들리'를 순서대로 나타낸 것이다.
      이때 무대에서 불렀던 곡으로 옳지 <u>않은</u> 것을 있는 대로 고른 것은? [3점]
      </div>
      <div class="q_picbox" style="border: 0px solid;">
      <img src="/assets/q_img/qr_01.png" />
      </div>
          `,
    options: ["ㄱ, ㄷ", "ㄷ, ㅁ", "ㄷ, ㅂ", "ㄱ, ㄷ, ㅁ", "ㄷ, ㄹ, ㅂ"],
    correctAnswer: 2,
    explanation: `
    우주소녀 스페셜 메들리에서 1. MoMoMo 2. 비밀이야 3. 너에게 닿기를 4. 부탁해 5. La La Love 6. BUTTERFLY 7. UNNATURAL 8. Last Sequence 
    순으로 노래를 불렀다. 이때 부르지 않은 곡은 ㄷ. 'HAPPY,' ㅂ. '이루리'이다. 따라서 답은 ③ ㄷ, ㅂ이다.

    <div class="exp_tt02"> 
    참고사항
    </div>
   
     <a class="link01" href="https://youtu.be/fijXBy2BNLU" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[우주소녀] 떼창에 감동받은 메보즈의 우주소녀 메들리♬
    </p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/fijXBy2BNLU</span> 
    </div>
    </a>
    `,
    score: 3,
  },

  {
    id: 22,
    type: "multiple-choice" as "multiple-choice",
    question: `
      <div class="q_box">
          (가), (나) 가사에 대한 설명으로 옳은 것만을
          <보기>에서 있는 대로 고른 것은? [4점]
      </div>

      <div class="pvbox02" style="padding: 16px 29px;">
          <p style="
              margin-bottom: 6px;
              ">
              <b>(가)</b>
          <div style="
              margin-bottom: 30px;
              ">
              밝게 빛나는 넌 나의 꿈이었고<br/>
              너는 별이었어<br/>
              But you're like a shooting star<br/>
              어느새 내게 다가온 넌 너무 가까웠고<br/>
              지금까지 기다린 건 넌 내게 아까웠어<br/>
              <span class="blankBox" style="padding: 3px 43px;
              margin-left: 1px;
              margin-right: 3px;
              border: 1px solid;">ⓐ</span> Like cosmic star<br/>
              우린 가까워질 수 없고<br/>
              Cuz I'm a cosmic girl<br/>
              지구와 달처럼 이어질 수 없어<br/>
              ⓑ <u>I need you bae 이렇게 말하는 난<br/>
              지금도 니가 너무 보고 싶은데</u><br/>
              But I still miss you you still love me<br/>
              우린 어쩔 수 없는 사이 야이야이야이야<br/>
              떼놓으려 해도 네 주윌 자꾸 맴돌면<br/>
              ⓒ <u>언젠간 가까워질 수 있겠지</u><br/>
  <div style="text-align: right; margin-top: 12px;">- 우주소녀, 『우주정거장』-</div>
            </div>
          </p>

          <p style="
              margin-bottom: 6px;
              ">
              <b>(나)</b>
          <div>
          ㉠ <u>우리의 저 먼 미래에도 다 보일 거야</u><br/>
          우리의 저 넓은 정원 속에<br/>
          너와 나의 입맞춤<br/>
          저 꽃들도 할거야 질투<br/>
          다만 알 거야 우리 둘의 영원함<br/>
          이룰 거야 다 여긴 It's such a beautiful<br/>
          ㉡ <u>스쳐가는 계절 속에 우리 꽃을 피워</u><br/>
          우리 꽃을 피워<br/>
          지지 않는 세상 속 너와 나<br/>
          여기 너와 나<br/>
          우리의 정원으로 와<br/>
          실려오는 향기 속에 너와 내가 있어<br/>
          지금 이 풍경이 좋아<br/>
          보이니 Beautiful beautiful<br/>
          여기 우리의 정원<br/>
          스쳐가는 계절 속에 우리 꽃을 피워<br/>
          Beautiful beautiful<br/>
          지지 않는 세상 속 너와 나<br/>
          Beautiful beautiful 여기 우리의 정원<br/>
  <div style="text-align: right; margin-top: 12px;">-우주소녀, 『우리의 정원』­-</div>
         </div>
      </div>

            <div class="pvbox_02">
                <div class="q_pvtt_02">&lt;보 기&gt;</div>
                <div class="q_pvtt_03">
                <p class="indent_text">ㄱ. ⓐ에 들어갈 가사는 ‘너무 아쉬워’이다.</p>
                <p class="indent_text">ㄴ. ⓑ를 부른 멤버가 이 곡의 작사, 작곡을 맡았다.</p>
                <p class="indent_text">ㄷ. '우주정거장'에서 ⓒ 부분을 부른 멤버는 다영, 보나, 루다이다.</p>
                <p class="indent_text">ㄹ. ㉠을 부른 멤버는 이 곡의 작사, ㉡을 부른 멤버는 작사, 작곡을 맡았다.</p>
                </div>
            </div>

      `,
    options: ["ㄱ, ㄴ", "ㄱ, ㄷ", "ㄱ, ㄴ, ㄹ", "ㄴ, ㄷ, ㄹ", "ㄱ, ㄴ, ㄷ, ㄹ"],
    correctAnswer: 3,
    explanation: `
 <p class="indent_text">ㄱ. (가) ⓐ에 들어갈 가사는 '너무 아쉬워'가 아닌 '너무 어려워'이므로 틀린 선지이다.</p>
<p class="indent_text">ㄴ. (가) ⓑ 'I need you bae 이렇게 말하는 난 지금도 니가 너무 보고 싶은데' 파트를 
부른 멤버는 다원으로 이 곡의 작사, 작곡에 참여했으므로 옳은 선지이다. </p>
<p class="indent_text">ㄷ. (가) '우주정거장'에서 ⓒ '언젠간 가까워질 수 있겠지' 파트를 부르는 멤버로는 다영, 보나, 루다이므로 옳은 선지이다. </p>
<p class="indent_text">ㄹ. (나) '우리의 정원' ㉠ '우리의 저 먼 미래에도 다 보일 거야'를 부르는 멤버는 엑시이며,
 ㉡ '스쳐가는 계절 속에 우리 꽃을 피워'부분을 부르는 멤버는 설아이다. 엑시가 작사, 설아는 작사, 작곡을 맡았으므로 옳은 선지이다. </p>
따라서 답은 ④ ㄴ, ㄷ, ㄹ 이다.

<div class="exp_tt02"> 
참고사항
</div>

 <a class="link01" href="https://youtu.be/9yKT9PKwrlc" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>WJSN ONLINE CONCERT 우주소녀 - 우주정거장</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/9yKT9PKwrlc</span> 
</div>
</a>
<a class="link01" href="https://youtu.be/9iTIX3JOZWw" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>NEVERLAND SHOWCASE 우주소녀 - 우리의 정원</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/9iTIX3JOZWw</span> 
</div>
</a>
    `,
    score: 4,
  },

  {
    id: 23,
    type: "multiple-choice" as "multiple-choice",
    question: `
      <div class="q_box">
       다음은 우주소녀의 커버 무대에 대한 네 우정의 대화이다.
       <div class="q_picbox" style="border: 0px solid;margin-top: 13px;">
         <img src="/assets/q_img/qf_01.png" />
      </div>
     우정 A~D 중 제시한 내용이 옳은 우정만을 있는 대로 고른 것은? [3점]
    </div>
    `,
    options: ["B", "C", "B, D", "A, C, D", "B, C, D"],
    correctAnswer: 1,
    explanation: `
    '주문 (Mirotic)' 커버 무대는 총 다섯명으로 엑시, 다원, 은서, 여름, 연정이 무대를 선보였다. A는 옳지 않은 내용이다. 
    'I NEED U' 커버 무대는 'KCON 2017 LA'에서 무대를 선보였다. B는 옳지 않은 내용이다. 
    'Alligator' 커버 무대 중 랩 파트를 엑시와 은서가 함께 맡았으므로 C는 옳은 내용이다. 
    'Love Shot'과 'Tempo' 커버 중 'Love Shot'은 팬미팅과 방송 <런웨이브>에서 선보인 적이 있으나 
    'Tempo'는 원더케이 채널 <올라운돌> 콘텐츠로 댄스 커버를 했다. 따라서 옳지 않은 내용이다. 답은 ② C 이다.
    
    <div class="exp_tt02"> 
참고사항
</div>

 <a class="link01" href="https://youtu.be/jYLUHw8qXjw" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>[KCON 2018 JAPAN] 우주소녀 l MIROTIC</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/jYLUHw8qXjw</span> 
</div>
</a>
<a class="link01" href="https://youtu.be/ze4ylvE8uwY" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>[KCON 2017 LA] 우주소녀 l I NEED U</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/ze4ylvE8uwY</span> 
</div>
</a>
<a class="link01" href="https://youtu.be/1VsJfCWhy6U" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>190628 Alligator - 우주소녀(WJSN)</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/1VsJfCWhy6U</span> 
</div>
</a>   
<a class="link01" href="https://youtu.be/EooyEQ6Nelk" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>우주소녀 - Tempo </p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/EooyEQ6Nelk</span> 
</div>
</a>   
    `,
    score: 3,
  },

  {
    id: 24,
    type: "multiple-choice" as "multiple-choice",
    question: `
  <div class="q_box">
  그림 (가)~(다)는 ‘오늘의 우주복’이다.
  이에 대한 설명으로 옳은 것만을 <보기>에서 있는 대로 고른 것은? [4점]
  </div>
        <div class="q_picbox" style="margin-top: 13px;">
          <img src="/assets/q_img/qy_01.jpg" />
        </div>
        <div class="pvbox_02">
        <div class="q_pvtt_02">&lt;보 기&gt;</div>
        <div class="q_pvtt_03">
        <p class="indent_text">ㄱ. (가) - 우주소녀 멤버들은 이 날 음악 방송 대기실에서 분식을 먹었다.</p>
        <p class="indent_text">ㄴ. (가) - 엑시는 이 날 우주소녀가 1위를 한다면 머리를 밀겠다고 말했다.</p>
        <p class="indent_text">ㄷ. (나) - 이 날 음악 방송에서 1위한 후, 우주소녀 멤버들이 다같이 발을 모아 발 세레머니(발이팅)를 했다.</p>
        <p class="indent_text">ㄹ. (다) - 이 날의 엔딩 요정 멤버는 설아, 루다였다.</p>
        </div>
    `,
    options: ["ㄱ, ㄹ", "ㄷ, ㄹ", "ㄱ, ㄴ, ㄷ", "ㄴ, ㄷ, ㄹ", "ㄱ, ㄴ, ㄷ, ㄹ"],
    correctAnswer: 2,
    explanation: `
    (가)는 2019년 6월 11일 <더쇼> 녹화날이다. 이 날 우주소녀 멤버들은 음악 방송 대기실에서 분식을 먹었으므로 ㄱ은 옳은 설명이다. 
    ㄴ은 '우쭈테잎' 73화에서 '1위를 한다면 머리를 밀도록 하겠습니다.' 
    라고 1위를 하면 뭔들 못하겠냐면서 말한 적이 있다. ㄴ은 옳은 설명이다.
(나)는 2019년 6월 13일 <엠카운트다운> 녹화날이다. 이 날 'Boogie Up'으로 1위를 하고 앵콜 무대 때 
다같이 모여 발 세레머니를 했으므로 ㄷ은 옳은 설명이다. 
(다)는 2019년 6월 18일 <더쇼> 녹화날이다. ㄹ의 엔딩 요정 멤버는 설아, 루다가 아닌 엑시였다. 
따라서 틀린 설명이다. 답은 ③ ㄱ, ㄴ, ㄷ 이다.  
 
<div class="exp_tt02"> 
참고사항
</div>

 <a class="link01" href="https://youtu.be/56_fM3Uj1E8" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>우주소녀, 매력THE캠 [비하인드 더 쇼 190611]</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/56_fM3Uj1E8</span> 
</div>
</a>

<a class="link01" href="https://youtu.be/fMA3Dx2E6K8" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>[UZZU TAPE] EP.73 우주소녀, "Boogie Up" 1위의 순간!</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/fMA3Dx2E6K8</span> 
</div>
</a>

<a class="link01" href="https://youtu.be/z1LahFUhgMc" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>190613 우주소녀 'Boogie Up' 1위</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/z1LahFUhgMc</span> 
</div>
</a>

<a class="link01" href="https://youtu.be/_6BfTSoGtrw" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>190618 우주소녀 'Boogie Up'</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/_6BfTSoGtrw</span> 
</div>
</a>

  `,
    score: 4,
  },

  {
    id: 25,
    type: "multiple-choice" as "multiple-choice",
    question: `
        <div class="q_box">
        (가)~(마)는 우주소녀 멤버의 인이어 사진이다.
        (가)~(마)에 대한 설명으로 옳은 것만을 <보기>에서 있는 대로 고른 것은? [4점]
        <div class="q_picbox" style="border: 0px solid;margin-top: 13px;">
          <img src="/assets/q_img/qx_01.png" />
        </div>
      </div>
      <div class="pvbox_02">
              <div class="q_pvtt_02">&lt;보 기&gt;</div>
              <div class="q_pvtt_03">
              <p class="indent_text">ㄱ. (가)와 (라)의 멤버는 동일 인물이다.</p>
              <p class="indent_text">ㄴ. (나)와 (마)는 동갑이며 우주소녀 유닛 활동을 함께했다.</p>
              <p class="indent_text">ㄷ. (다)와 (마)는 곡 ‘Done’에서 페어 안무가 있다.</p>
              <p class="indent_text">ㄹ. (라)는 우주소녀에서 메인 보컬을 맡고 있다.</p>
              </div>

      `,
    options: ["ㄱ, ㄹ", "ㄴ, ㄷ", "ㄴ, ㄹ", "ㄱ, ㄷ, ㄹ", "ㄴ, ㄷ, ㄹ"],
    correctAnswer: 1,
    explanation: `
    멤버들의 인이어는 차례대로 (가) 다원, (나) 엑시, (다) 설아, (라) 여름, (마) 보나이다. 
보기의 ㄱ의 (가)와 (라)는 다원, 여름이다. 동일 인물이 아니므로 틀린 설명이다. ㄴ의 (나)와 (마)는 
엑시와 보나이므로 1995년생으로 동갑이며 '더 블랙'으로 유닛 활동을 함께 했으므로 옳은 설명이다. 
ㄷ의 (다)와 (마)는 설아와 보나다. 
‘Done’ 중 'Maybe 처음부터 어긋난 거야' 파트에 설아, 보나 페어 안무가 있으므로 옳은 설명이다. 
ㄹ의 (라)는 여름이다. 메인 보컬은 이 중 (가) 다원이 맡고 있으므로 틀린 설명이다. 따라서 답은 ② ㄴ, ㄷ 이다.
  
<div class="exp_tt02"> 
참고사항
</div>

<div class="q_picbox" style="border: 0px solid;margin-top: 13px;">
<img src="/assets/a_img/a_z01.png" />
</div>
</div>

 <a class="link01" href="https://youtu.be/Zc849rxLpfw" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>2023 WJSN (우주소녀) FAN - CON 'Codename : UJUNG' - DONE</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/Zc849rxLpfw</span> 
</div>
</a>

`,
    score: 4,
  },

  {
    id: 26,
    type: "multiple-choice" as "multiple-choice",
    question: `
      <div class="q_box">
      그림은 A~E 우주소녀가 출연했던 예능 프로그램이고,
      표 (나)는 예능 프로그램의 ㉮~㉲의 특징 ㉠~㉤의 옳고 그름을 나타낸 것이며,
      (가)는 ㉠~㉤을 순서 없이 나타낸 것이다. ㉮~㉲는 A~E를 순서 없이 나타낸 것이다.

        <div class="q_picbox" style="margin-top: 13px;">
          <img src="/assets/q_img/qd_01.jpg" />
          </div>
          <div class="q_picbox" style="border: 0px solid;margin-top: 13px;">
          <img src="/assets/q_img/qd_02.png" />
          </div>
      이에 대한 설명으로 옳은 것만을 <보기>에서 있는 대로 고른 것은? [4점]
        </div>
            <div class="pvbox_02">
              <div class="q_pvtt_02">&lt;보 기&gt;</div>
              <div class="q_pvtt_03">
              <p class="indent_text">ㄱ. ⓐ는 ‘○’ 이다.</p>
              <p class="indent_text">ㄴ. ㉮에서 멤버 전원이 '꿈꾸는 마음으로' 무대 의상을 입고 출연했다.</p>
              <p class="indent_text">ㄷ. ㉰에 출연한 멤버는 ㉲에 출연한 멤버와 유닛 활동을 했다.</p>
              <p class="indent_text">ㄹ. ㉱에 출연한 멤버는 ㉯에도 출연했다.</p>
              <p class="indent_text">ㅁ. ㉲에는 ‘우주소녀 더 블랙’ 멤버 전원과 수빈, 다영이 출연했다.</p>
              </div>
      `,
    options: [
      "ㄷ, ㅁ",
      "ㄱ, ㄴ, ㅁ",
      "ㄷ, ㄹ, ㅁ",
      "ㄱ, ㄴ, ㄷ, ㄹ",
      "ㄴ, ㄷ, ㄹ, ㅁ",
    ],
    correctAnswer: 3,
    explanation: `
    A~E와 (가)의 특징을 문제에 제시된 순서대로 나열하여 표를 만들어 (나)의 표를 파악한다.
    
    <div class="q_picbox" style="border: 0px solid;margin-top: 13px;">
<img src="/assets/a_img/ax_01.png" />
</div>
(다)는 문제에 나온 특징을 순서대로 지정하여 만든 표이다.<br/>
① '1회성 출연이다.', ② '지상파 방송이다.', ③ '멤버가 두 명 이상 출연했다.', ④ '타 출연진과 함께 출연했다.', ⑤ '2019년 후에 방영됐다.'
<br/>A : 출장 십오야2, B : 판벌려, C : 두니아, D : 진짜 사나이 300, E : 끼리끼리
 <br/><br/>
  
(나)의 ㉮를 보면 ⓐ는 ○ 또는 ×일 수도 있는 경우인데, 
(다)를 보면 ×가 1개인 경우는 A, ○가 5개인 경우는 E로 A, E 두 개뿐이다. 
(나)에서 A에 해당하는 건 ㉲밖에 없으므로 나머지 E는 ㉮일 수 밖에 없다. 
따라서 ㉮는 E인 ‘끼리끼리’이고, ㉲는 ‘출장 십오야2’이다. 
이때 ㉲의 특징 중 ㉠만이 ×인데 이것은 (다)의 A 특징 ②이므로 ㉠의 특징은 ‘지상파 방송이다.’가 된다. 
(나)의 특징 ㉢의 세로 열을 보면 모두 ○이다. (다)에서 모두 ○인 특징은 ④ ‘타 출연진들과 함께 출연했다.’뿐이다. 
따라서 ㉢의 특징은 ‘타 출연진들과 함께 출연했다.’가 된다. 
(나)의 ㉣ 세로 열 물음표에는 ○ 또는 ×일 수도 있는 경우인데, 
만약 ×일 경우엔 ×가 2개인 경우가 된다. 그 경우는 (다) ②, ③ 두 개의 경우 뿐이다. 
이미 ②는 ㉠에 해당하는 걸 알고 있으니, ③ ‘멤버가 두 명 이상 출연했다’의 특징은 ㉡이 된다. 
그럼 더 이상 ㉣ 세로 열의 물음표는 ×가 될 수 없으므로 ○이며, 
㉣의 특징은 (다) ①의 세로 열 중 ×가 한 개뿐인 특징인 ‘1회성 출연이다.’가 된다. 
그럼 남은 특징 하나인 ‘2019년 후에 방영됐다.’는 ㉤임을 알 수 있고 ㉤의 세로 열 물음표는 ×이다.
<br/><br/>
이를 통해서 특징과 방송명을 정리해보면, <br/>
㉠ ‘지상파 방송이다.’ ㉡ ‘멤버가 두 명 이상 출연했다’ 
㉢ ‘타 출연진들과 함께 출연했다.’ ㉣ ‘1회성 출연이다.’ ㉤ ‘2019년 후에 방영됐다.’ <br/>
㉮ ‘끼리끼리’ ㉯ ‘판벌려’ ㉰ ‘두니아’ ㉱ ‘진짜 사나이 300’ ㉲ ‘출장 십오야2’가 된다. 
<br/><br/>
<보기> ㄱ. ⓐ는 ○이므로 옳은 설명이다. 
ㄴ. ㉮ ‘끼리끼리’에서 우주소녀 멤버 전원이 ‘꿈꾸는 마음으로’ 무대 의상을 입고 출연했으므로 옳은 설명이다. 
ㄷ. ㉰ ‘두니아’에 출연한 멤버는 루다이다. 
㉲ ‘출장 십오야2’에 출연한 멤버는 엑시, 설아, 보나, 수빈, 다영이다. 
루다는 ‘쪼꼬미’로 수빈, 여름, 다영과 같이 유닛 활동을 했었으므로 옳은 설명이다. 
이와 동시에 <보기> ㅁ이 틀린 설명임을 알 수 있다. ‘더 블랙’ 멤버 전원 중 은서는 출연하지 않았다. 
ㄹ. ㉱ ‘진짜 사나이 300’에 출연한 멤버는 ‘은서’다. ㉯ ‘판벌려’에 출연했었으므로 옳은 설명이다. 따라서 답은 ④ ㄱ, ㄴ, ㄷ, ㄹ 가 된다.
 
<div class="exp_tt02"> 
참고사항
</div>

<a class="link01" href="https://youtu.be/GL8M2I1qVZ8" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>🧳출장십오야2 X 스타쉽:가을야유회</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/GL8M2I1qVZ8</span> 
</div>
</a>
<a class="link01" href="https://youtu.be/vaXN2opFeNg" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>180909 두니아 처음 만난 세계</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/vaXN2opFeNg</span> 
</div>
</a>
<a class="link01" href="https://youtu.be/PafdnkcN3d4" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>판벌려 - 이번 판은 한복판 9회</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/PafdnkcN3d4</span> 
</div>
</a>
<a class="link01" href="https://youtu.be/AH-ToUnuWtE" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>181207 진짜 사나이 300</p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/AH-ToUnuWtE</span> 
</div>
</a>
<a class="link01" href="https://youtu.be/V6hHkXl-RA4" target="_blank" rel="noopener noreferrer">
<div class="linkbox">
<p>200412 [끼리끼리] 우주 여전사단인데요? 흥끼리 잡으러 온 진짜 우주소녀! </p>
<span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/V6hHkXl-RA4</span> 
</div>
</a>
`,
    score: 4,
  },

  // 단답형 4문제
  {
    id: 27,
    type: "short-answer" as "short-answer",
    question: `
      <div class='q_box'>
      (가)~(라)의 빈칸 A~D에 들어갈 말을 순서대로 입력하시오. [3점]
      </div>
          <div class="q_picbox">
              <p>(가)</p>
              <img src="/assets/q_img/qg_01.jpg" />
        </div>

          <div class="q_picbox">
          <p>(나)</p>
          <img src="/assets/q_img/qg_02.jpg" />
          </div>

        <div class="q_picbox">
        <p>(다)</p>
        <img src="/assets/q_img/qg_03.jpg" />
        </div>

        <div class="q_picbox">
        <p>(라)</p>
        <img src="/assets/q_img/qg_04.jpg" />
        </div>
  `,
    correctAnswer: ["십", "돌판", "예의", "응있지"],
    explanation: `
    <div class="q_picbox">
    <p>(가)</p>
    <img src="/assets/a_img/as_01.jpg" />
</div>

<div class="q_picbox">
<p>(나)</p>
<img src="/assets/a_img/as_02.jpg" />
</div>

<div class="q_picbox">
<p>(다)</p>
<img src="/assets/a_img/as_03.jpg" />
</div>

<div class="q_picbox">
<p>(라)</p>
<img src="/assets/a_img/as_04.jpg" />
</div>


<div class="exp_tt02"> 
참고사항
</div>

<a class="link01" href="https://youtu.be/leWSZJfgNJw?si=6ivmjgObn0Tipnof&t=108" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>쓸데없이 해맑은 수빈이 시-입!!! 😂 플레이어 초토화 예능선배들 한 마디로 무너뜨린 숨겨진 십장생의 비밀</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/leWSZJfgNJw</span> 
    </div>
    </a>

    <a class="link01" href="https://youtu.be/iE8L4gAhv-A" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>아침이라서 얼굴이 예의가 없어가지고
    </p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/iE8L4gAhv-A</span> 
    </div>
    </a>
    `,
    score: 3,
  },

  {
    id: 28,
    type: "short-answer" as "short-answer",
    question: `
      <div class='q_box'>
      다음 글을 읽고 A~C에 들어갈 말을 순서대로 입력하시오. (단, 영어는 한글 발음대로 입력한다.) [3점]
      </div>
      <div class="pvbox02">
        <p style="margin: 0;">
        우주소녀 데뷔 전 팀 이름은 <span class="blankBox">A</span>였으며,
        국내 유명 댄서인 <span class="blankBox">B</span>에게
        '스트릿 댄스' 장르 중 하나인 <span class="blankBox">C</span>를 배웠었다.
        </p>
      </div>
  `,
    correctAnswer: ["유니버스", "허니제이", "걸스힙합"],
    explanation: `
    우주소녀는 데뷔 전 팀이름은 '유니버스'였다. 데뷔 전 허니제이에게 걸스힙합을 배웠었다.
    <div class="exp_tt02"> 
참고사항
</div>

<p>'우주소녀' 이름의 탄생</p>
<a class="link01" href="https://youtu.be/SCxHb6_DEIY?si=HHVp-FEjkNcw3eXJ&t=529" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[우주소녀] 연습생 썰 1탄 (6주년 축하해!!)</p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/SCxHb6_DEIY</span> 
    </div>
    </a>
    
 <a class="link01" href="https://youtu.be/jFmDKkH6xDI?si=v54-GourtZtspFEq&t=76" target="_blank" rel="noopener noreferrer">
    <div class="linkbox">
    <p>[우주소녀]스우파 찐팬 모먼트 모음(+허니제이쌤에게 걸스힙합 배우다가 모모모로 데뷔한 썰 푼다)
    </p>
   <span><img src="./assets/player.svg" className="playersvg" />https://youtu.be/jFmDKkH6xDI</span> 
    </div>
    </a>
    
    `,
    score: 3,
  },

  {
    id: 29,
    type: "short-answer" as "short-answer",
    question: `
		<div class='q_box'>
    우주소녀 곡 ‘HAPPY’의 치어리더 무대 의상에 적힌 각 멤버의 번호를 기준으로 다음 식의 값을 구하시오. [4점]
		</div>
      <div class="q_picbox" style="border: 0px solid;margin-top: 28px;margin-bottom: 28px;">
    <img src="/assets/q_img/qm_01.png" />
              </div>
`,
    correctAnswer: "225",
    explanation: `
    ‘HAPPY’ 의상에 적힌 멤버들의 번호는<br/>
엑시 11, 설아 12, 보나 08, 수빈 09, 루다 03, 다원 16, 은서 27, 여름 01, 다영 14, 연정 83이다. <br/>
이를 토대로 주어진 식에 대입하여 풀이한다.<br/><br/>
  
<div class="exp_tt02"> 
정답 풀이
</div>
<div class="q_picbox" style="margin-left: 0;border:0;max-width: 480px;">
 <img src="/assets/a_img/aq_01.png" />
</div>
주어진 식에 멤버들의 'HAPPY' 치어리더 의상 번호를 대입한다.<br/><br/>
<div class="q_picbox" style="margin-left: 0;border:0;max-width: 260px;">
 <img src="/assets/a_img/aq_02.png" />
</div>
11과 8과 83을 더하여 102를 구하고, 14와 1을 더하여 15를 구한다. 
9를 3으로 나눠 3을 구한다. <br/><br/>
<div class="q_picbox" style="margin-left: 0;border:0;max-width: 200px;">
 <img src="/assets/a_img/aq_03.png" />
</div>
3과 16과 27를 곱하여 1296을 구한 뒤, 1296을 12로 나눠서 108을 구한다.<br/><br/>
<div class="q_picbox" style="margin-left: 0;border:0;max-width: 260px;">
 <img src="/assets/a_img/aq_04.png" />
</div>
102와 108과 15를 모두 더하여 225를 구한다. <br/>
따라서 답은 225가 된다.

<div class="exp_tt02"> 
참고사항
</div>

<div class="q_picbox" style="border:0;">
 <img src="/assets/a_img/az_01.png" />
</div>
`,
    score: 4,
  },

  {
    id: 30,
    type: "short-answer" as "short-answer",
    question: `
		<div class='q_box'>
    그림 (가)~(차)는 우주소녀 ‘V LIVE’ 목록이다. 빈칸 A~J에 들어갈 제목을 순서대로 입력하시오. [4점]
		</div>
    <div class="q_picbox" style="margin-top: 13px;">
          <img src="/assets/q_img/qh_01.jpg" />
        </div>
`,
    correctAnswer: [
      "오늘의",
      "플레이리스트",
      "전지적",
      "문",
      "헬스교실",
      "마음대로",
      "희희낙락",
      "요리교실",
      "우쥬크박스",
      "우주뉴스",
    ],
    explanation: `
    우주소녀 멤버들이 했던 V LIVE 시리즈 이름이다. <br/><br/>
은서 : 오늘의 이야기<br/>
연정 : 연정이의 플레이리스트<br/>
설아 : 전지적 설아 시점<br/>
여름 : 여름이가 문 엶<br/>
다원 : 다원이의 헬스교실<br/>
보나/은서 : 연자매의 마음대로<br/>
루다/은서 : 단쟌단쟌의 희희낙락<br/>
다영 : 다영이의 요리교실<br/>
엑시/설아 : 엑떨의 우쥬크박스 <br/>
수빈/여름 : 뚜름의 우주뉴스<br/>
    
    `,
    score: 4,
  },
  // ...other questions...
];

export default questionsData;
