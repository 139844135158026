// MainPage.tsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/MainPage.css";
import { generateRandomString } from "../utils/RandomUtils";
// setName 프롭의 타입 정의

const MainPage: React.FC = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const handleStartClick = () => {
    window.scrollTo(0, 0);
    const examNumber = generateRandomString(7);
    navigate("/exam", { state: { name, examNumber } });
    // console.log("이름 난수번호", name, examNumber);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <main>
      <header className="main_hd">
        <div className="main_hd02">
          <div className="hdtt_01">제 1 교시</div>
          <div className="hdlogo">
            <img src="./assets/wjsnlogo.svg" className="logosvg" alt="img" />
          </div>
        </div>
        <div className="test_title03">2024학년도 우주소녀능력시험 문제지</div>
        <div className="test_title04">우주소녀 영역</div>
      </header>

      <div className="namebox">
        <div className="namett">성명</div>
        <input
          className="nametext"
          type="text"
          placeholder="성명을 입력하시오."
          value={name}
          onChange={handleNameChange}
          maxLength={11}
        />
      </div>

      {/* <div className="examnumbox">
        <div className="examtt_01">수험 번호</div>
        <div className="numbox">2</div>
        <div className="numbox">0</div>
        <div className="numbox">1</div>
        <div className="numbox">6</div>
        <div className="numbox">-</div>
        <div className="numbox">0</div>
        <div className="numbox">2</div>
        <div className="numbox">2</div>
        <div className="numbox_end">5</div>
      </div> */}

      <div className="examsignbox">
        <ul className="exlist">
          <li>문제지의 해당란에 성명을 입력하시오.</li>
          <li>
            답안지의 필적 확인란에 다음의 문구를 정자로 기재하시오.
            <div className="signtext">2월의 봄처럼 언제나 따뜻한 너와</div>
          </li>
          <li>시험 시간은 20분으로 시간 내에 답안을 제출하시기 바랍니다.</li>
          <li>시험 응시자에게 우정증을 발급해드립니다.</li>
          <li>
            우주소녀 데뷔 8주년 기념으로 만든 것이니 재미로 풀어주시면
            감사하겠습니다.
          </li>
          {/* <li>
            문항 이의제기는
            <span
              className="bloglink"
              onClick={toggleBlogLink}
              ref={blogLinkRef}
            >
              {showBlogLink && (
                <div className="bloglinkbox">
                  <a href="https://" target="_blank" rel="noreferrer">
                    방명록
                  </a>
                </div>
              )}
              이곳
            </span>
            으로 방문하여 글을 남겨주시기 바랍니다.
          </li> */}
        </ul>
      </div>

      <div className="mainbtnbox">
        <button
          className="startbtn"
          onClick={handleStartClick}
          disabled={!name}
        >
          응시하기
        </button>
      </div>

      <div className="cprbox">우소덕질과정평가원</div>
    </main>
  );
};

export default MainPage;
