// Questions.tsx
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MultipleChoiceQuestion from "../components/MultipleChoiceQuestion";
import ShortAnswerQuestion from "../components/ShortAnswerQuestion";
import questionsData from "../utils/questionsData";
import Loading from "../components/Loading";
import DOMPurify from "dompurify";
import { useAudioPlayers } from "../utils/useAudioPlayers";

import "../styles/Questions.css";
import "../styles/Questions02.css";

const Questions: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<{
    [key: number]: string | number | string[];
  }>({});

  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가
  const currentQuestion = questionsData[currentQuestionIndex];

  const questions = questionsData;

  const navigate = useNavigate();
  const location = useLocation();

  const name = location.state?.name; // MainPage에서 전달된 성명

  const [timeLeft, setTimeLeft] = useState(1200); // 초 단위

  // 타이머
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // 시간이 0에 도달하면 사용자에게 경고하고 메인 페이지로 이동
    if (timeLeft <= 0) {
      alert("시간 초과 되었습니다. 메인으로 돌아갑니다");
      navigate("/");
    }
  }, [timeLeft, navigate]);

  // 듣기 평가
  // 각 오디오 파일에 대한 재생 상태
  const audioFiles = [
    "./assets/sound/01번.mp3",
    "./assets/sound/02번.mp3",
    "./assets/sound/03번.mp3",
    "./assets/sound/04번.mp3",
    "./assets/sound/05번.mp3",
  ];
  const { isPlaying, isPlayed, playPause, resetPlayer } =
    useAudioPlayers(audioFiles);

  useEffect(() => {
    if (!name) {
      navigate("/");
    }
  }, [name, navigate]);

  const handleSubmit = () => {
    window.scrollTo(0, 0);

    setIsLoading(true); // 로딩 상태 활성화
    const score = calculateScore(answers);

    setTimeout(() => {
      setIsLoading(false); // 로딩 상태 비활성화
      navigate("/result", {
        state: { score, name, answers, examNumber: location.state.examNumber },
      });
    }, 100);
  };

  if (isLoading) {
    return <Loading />; // 로딩 컴포넌트 표시
  }

  // 답변 변경 처리 함수
  const handleAnswerChange = (
    id: number,
    answer: string | number | string[]
  ) => {
    let formattedAnswer = answer;

    // 객관식 답변일 경우 숫자로 변환
    if (typeof answer === "string" && !isNaN(answer as any)) {
      formattedAnswer = Number(answer);
    }
    setAnswers((prev) => ({ ...prev, [id]: formattedAnswer }));
  };

  const goToPreviousQuestion = () => {
    window.scrollTo(0, 0);
    if (isPlaying[currentQuestionIndex] && !isPlayed[currentQuestionIndex]) {
      resetPlayer(currentQuestionIndex);
    }
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else {
      if (
        window.confirm(
          "처음으로 돌아가시겠습니까? 지금까지 작성한 답은 초기화 됩니다."
        )
      ) {
        window.scrollTo(0, 0);
        navigate("/", { state: { name, answers } });
      }
    }
  };

  const goToNextQuestion = () => {
    window.scrollTo(0, 0);

    const currentAnswer = answers[questions[currentQuestionIndex].id];
    if (isPlaying[currentQuestionIndex] && !isPlayed[currentQuestionIndex]) {
      resetPlayer(currentQuestionIndex);
    }
    if (currentAnswer !== undefined && currentAnswer !== "") {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      alert("답을 입력해주세요."); // 답변이 없을 경우 경고 메시지
    }
  };

  const isAnswerProvided = () => {
    const currentAnswer = answers[questions[currentQuestionIndex].id];
    return currentAnswer !== undefined && currentAnswer !== "";
  };

  // 문제 렌더링
  const renderQuestion = () => {
    const currentQuestion = questions[currentQuestionIndex];
    // currentAnswer가 배열이 아니면 빈 배열로 초기화 (short-answer 타입의 경우에만)
    let currentAnswer: string | number | string[] = answers[currentQuestion.id];
    if (currentQuestion.type === "short-answer") {
      currentAnswer = Array.isArray(currentAnswer) ? currentAnswer : [];
    }

    switch (currentQuestion.type) {
      case "multiple-choice":
        return (
          <MultipleChoiceQuestion
            {...currentQuestion}
            onChange={(id, answer) => handleAnswerChange(id, answer)}
            currentAnswer={currentAnswer as number}
          />
        );
      case "short-answer":
        return (
          <ShortAnswerQuestion
            {...currentQuestion}
            id={currentQuestion.id}
            question={currentQuestion.question}
            onChange={handleAnswerChange}
            currentAnswer={currentAnswer}
          />
        );
      default:
        return null;
    }
  };

  const totalQuestions = questions.length;
  const currentQuestionNumber = currentQuestionIndex + 1; // 현재 문항 번호 (0부터 시작하므로 1을 더함)
  const createMarkup = (html: any) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  // 채점 로직
  function calculateScore(answers: {
    [key: number]: string | number | string[];
  }) {
    let totalScore = 0;
    for (const question of questions) {
      // userAnswer가 배열일 수 있으므로 적절한 타입 체크와 처리가 필요
      const userAnswer = answers[question.id];

      if (Array.isArray(question.correctAnswer)) {
        // 여러 개의 정답 처리
        if (Array.isArray(userAnswer)) {
          const isCorrect = question.correctAnswer.every(
            (ans, index) => ans === userAnswer[index]
          );
          if (isCorrect) {
            totalScore += question.score;
          }
        }
      } else {
        // 단일 정답 처리
        if (String(userAnswer) === String(question.correctAnswer)) {
          totalScore += question.score;
        }
      }
    }
    return totalScore;
  }

  return (
    <main>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@24,400,1,0"
      />
      {/* 문제 풀이 현황  */}
      <div>
        <div className="timer">
          <img src="./assets/alarm.svg" className="alarmicon" alt="img" />
          {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, "0")}
        </div>
        <div className="progress-bar">
          <div
            className="progress"
            style={{
              width: `${((currentQuestionIndex + 1) / totalQuestions) * 100}%`,
            }}
          />
        </div>
        {/* 남은 시간 표시 */}
      </div>

      {/* 문항 */}
      <div className="q_contain">
        {currentQuestionNumber === 1 && (
          <div className="q_type_l">
            　1번부터 5번까지는 듣고 답하는 문제입니다. 듣기는 한 번만
            들려줍니다. 방송을 잘 듣고 답을 하시기 바랍니다. 재생 버튼을 누르면
            듣기 문제가 재생 됩니다.
          </div>
        )}

        {currentQuestionNumber === 6 && <div className="q_type">5지선다형</div>}
        {currentQuestionNumber === 27 && <div className="q_type">단답형</div>}
        <div className="q_num">{currentQuestionNumber}.</div>
        <div dangerouslySetInnerHTML={createMarkup(currentQuestion.question)} />
      </div>

      {currentQuestionNumber === 1 && (
        <div
          className="q_sound"
          onClick={() => playPause(0)} // 첫 번째 오디오에 대해 playPause 함수 호출
          style={{ backgroundColor: isPlayed[0] ? "grey" : "white" }}
        >
          <img
            src={
              isPlaying[0] ? "./assets/pause.svg" : "./assets/play_arrow.svg"
            }
            className="playicon"
            alt="img"
          />
          <div>1번 듣기 평가 문제</div>
        </div>
      )}

      {currentQuestionNumber === 2 && (
        <div
          className="q_sound"
          onClick={() => playPause(1)}
          style={{ backgroundColor: isPlayed[1] ? "grey" : "white" }}
        >
          <img
            src={
              isPlaying[1] ? "./assets/pause.svg" : "./assets/play_arrow.svg"
            }
            className="playicon"
            alt="img"
          />
          <div>2번 듣기 평가 문제</div>
        </div>
      )}

      {currentQuestionNumber === 3 && (
        <div
          className="q_sound"
          onClick={() => playPause(2)}
          style={{ backgroundColor: isPlayed[2] ? "grey" : "white" }}
        >
          <img
            src={
              isPlaying[2] ? "./assets/pause.svg" : "./assets/play_arrow.svg"
            }
            className="playicon"
            alt="img"
          />
          <div>3번 듣기 평가 문제</div>
        </div>
      )}

      {currentQuestionNumber === 4 && (
        <div
          className="q_sound"
          onClick={() => playPause(3)}
          style={{ backgroundColor: isPlayed[3] ? "grey" : "white" }}
        >
          <img
            src={
              isPlaying[3] ? "./assets/pause.svg" : "./assets/play_arrow.svg"
            }
            className="playicon"
            alt="img"
          />
          <div>4번 듣기 평가 문제</div>
        </div>
      )}

      {currentQuestionNumber === 5 && (
        <div
          className="q_sound"
          onClick={() => playPause(4)}
          style={{ backgroundColor: isPlayed[4] ? "grey" : "white" }}
        >
          <img
            src={
              isPlaying[4] ? "./assets/pause.svg" : "./assets/play_arrow.svg"
            }
            className="playicon"
            alt="img"
          />
          <div>5번 듣기 평가 문제</div>
        </div>
      )}

      {renderQuestion()}

      {currentQuestionNumber === 30 && (
        <div className="q_last">
          * 확인 사항
          <br />
          ◦답안지의 해당란에 필요한 내용을 정확히 기입&#40;표기&#41; 했는지 확인
          하시오.
        </div>
      )}

      {/* 이전, 다음 버튼 */}
      <div className="prvnextbtnbox">
        <button className="prvbtn" onClick={goToPreviousQuestion}>
          이전
        </button>

        <div className="qnumbox">
          {currentQuestionNumber} / {totalQuestions}
        </div>

        {currentQuestionIndex < questions.length - 1 ? (
          <button
            className="nextbtn"
            onClick={goToNextQuestion}
            disabled={!isAnswerProvided()}
          >
            다음
          </button>
        ) : (
          <button
            className="nextbtn"
            onClick={handleSubmit}
            disabled={!isAnswerProvided()}
          >
            제출하기
          </button>
        )}
      </div>
    </main>
  );
};

export default Questions;
