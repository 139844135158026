// AudioPlayer.ts

export default class AudioPlayer {
  private audio: HTMLAudioElement;
  private isPlaying: boolean;

  constructor(audioFile: string) {
    this.audio = new Audio(audioFile);
    this.audio.volume = 1.0;
    this.isPlaying = false;

    this.audio.addEventListener("ended", () => {
      this.isPlaying = false;
    });
  }

  playOnce() {
    if (!this.isPlaying) {
      this.audio.play();
      this.isPlaying = true;
    }
  }

  pause() {
    if (this.isPlaying) {
      this.audio.pause();
      this.isPlaying = false;
    }
  }

  reset() {
    this.audio.currentTime = 0;
  }

  isCurrentlyPlaying() {
    return this.isPlaying;
  }

  onEnded(callback: () => void) {
    this.audio.addEventListener("ended", callback);
  }
}
