import React, { useEffect, useState } from "react";
import { QuestionProps } from "./Question";

const ShortAnswerQuestion: React.FC<QuestionProps> = ({
  id,
  question,
  onChange,
  correctAnswer,
  currentAnswer,
}) => {
  const answerCount = Array.isArray(correctAnswer) ? correctAnswer.length : 1;

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const answerLabels = alphabet.slice(0, answerCount).split("");

  // 현재 사용자의 답변을 상태로 관리합니다.
  const [answers, setAnswers] = useState<Array<string>>(
    Array.isArray(currentAnswer) && currentAnswer.length === answerCount
      ? currentAnswer
      : Array(answerCount).fill("")
  );

  useEffect(() => {
    // currentAnswer가 변경될 때마다 상태를 업데이트합니다.
    if (Array.isArray(currentAnswer) && currentAnswer.length === answerCount) {
      setAnswers(currentAnswer);
    } else {
      setAnswers(Array(answerCount).fill(""));
    }
  }, [currentAnswer, answerCount]);

  const handleChange = (index: number, value: string) => {
    const cleanedValue = value.replace(/\s/g, "");

    const newAnswers = [...answers];
    newAnswers[index] = cleanedValue;
    setAnswers(newAnswers);
    onChange(id, newAnswers);
  };

  return (
    <div className="answercontain">
      {answers.map((answer, index) => (
        <div key={index} className="answer">
          {answerCount > 1 && (
            <span className="answer-label">{answerLabels[index]}</span>
          )}
          <input
            className="asform"
            type="text"
            value={answer}
            onChange={(e) => handleChange(index, e.target.value)}
            placeholder={`답을 입력하시오.`}
          />
        </div>
      ))}
    </div>
  );
};

export default ShortAnswerQuestion;
