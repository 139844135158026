import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import Questions from "./pages/Questions";
import Results from "./pages/Results";
import ReviewAnswers from "./pages/ReviewAnswers";
import Header from "./components/Header";
import Answers from "./pages/Answers";

import "./App.css";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route
          path="/exam"
          element={
            <>
              <Header />
              <Questions />
            </>
          }
        />
        <Route
          path="/result"
          element={
            <>
              <Header />
              <Results />
            </>
          }
        />
        <Route
          path="/answers"
          element={
            <>
              <Header />
              <Answers />
            </>
          }
        />
        <Route
          path="/review"
          element={
            <>
              <Header />
              <ReviewAnswers />
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
