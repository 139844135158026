// Results.tsx
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import "../styles/Results.css";
import { getGrade, getGradeDetails, getPosition } from "../utils/GradeUtils";

const Results: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { score, name, examNumber } = location.state || {};
  const currentDate = new Date().toLocaleDateString();

  const resultRef = useRef(null);
  const [resultImage, setResultImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const grade = getGrade(score);
  const position = getPosition(score);
  const { image } = getGradeDetails(grade);

  useEffect(() => {
    if (score == null) {
      navigate("/");
    } else {
      handleGenerateImage();
    }
  }, [score, navigate]);

  const handleGenerateImage = async () => {
    if (resultRef.current) {
      try {
        const canvas = await html2canvas(resultRef.current);
        const base64Image = canvas.toDataURL("image/png");

        // Base64 문자열에서 필요없는 부분 제거
        const base64Response = await fetch(base64Image);
        const blob = await base64Response.blob();

        // Blob 객체를 이용해 파일 형식의 URL 생성
        const imageUrl = URL.createObjectURL(blob);
        setResultImage(imageUrl);
      } catch (error) {
        console.error("Error generating image:", error);
      } finally {
        // 이미지 생성 후 로딩 상태 해제
        setTimeout(() => {
          window.scrollTo(0, 0);
          setIsLoading(false);
        }, 30);
      }
    }
  };

  const goToAnswers = () => {
    window.scrollTo(0, 0);
    navigate("/answers", {
      state: { name, score, answers: location.state.answers, examNumber },
    });
  };

  const handleRetake = () => {
    window.scrollTo(0, 0);
    navigate("/");
  };

  const handleReviewAnswers = () => {
    window.scrollTo(0, 0);
    navigate("/review", {
      state: { name, score, answers: location.state.answers, examNumber },
    });
  };

  return (
    <main>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
      />
      {isLoading && (
        <div className="loading-overlay">
          <p>우정증 발급 중...</p>

          <div className="ldbox">
            <img src="/assets/img/uj_01.png" alt="img" />
          </div>
        </div>
      )}

      <div className="cardname">{name}님의 우정증</div>
      <div
        className="result"
        ref={resultRef}
        // style={{ backgroundImage: `url('/assets/frame.png')` }}
        style={{
          backgroundImage: `url('/assets/img/frame.png')`,
          display: resultImage ? "none" : "block",
        }}
      >
        {/* 우정증 */}
        <div className="result_02">
          <div
            className="uj_pic"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          <div className="uj_no">{examNumber}</div>
          <div className="uj_sc">{score}점</div>
          <div className="uj_gd">{grade}</div>
          <div className="uj_nm">{name}</div>
          <div className="uj_ps">{position}</div>
          <div className="uj_dt">{currentDate}</div>
        </div>
      </div>

      <div className="idcardcontain">
        {resultImage && <img src={resultImage} alt="우정증" />}
      </div>
      <p className="idcard_tt">사진을 꾹 누르면 우정증을 저장할 수 있습니다.</p>

      <div className="idbtncontain">
        <div className="idbtn">
          <a download="우정증.png" href={resultImage}>
            우정증 저장하기
          </a>
        </div>
      </div>

      <div className="rt_btncontain">
        <button className="ra_btn" onClick={goToAnswers}>
          정답표
        </button>
        <button className="ra_btn" onClick={handleReviewAnswers}>
          정답 및 해설
        </button>

        <button className="re_btn" onClick={handleRetake}>
          다시 풀어보기
        </button>
      </div>

      <div className="cprbox">우소덕질과정평가원</div>
    </main>
  );
};

export default Results;
